<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'transformtranslatemenu',
  props: {
    options: Array,
    name: String
  },
  computed: {
    currentTransform () {
      return this.$store.getters['transform/getItemByName'](this.name)
    }
  },
  methods: {
    setTransform (transform, option) {
      this.$store.dispatch('transform/setCurrentTransform', {transform: transform, option: option})
      console.log('option.payload', option.payload)
      configure.translate(transform, option)
    },    
  }  
}
</script>

<template>
<div>
  <v-sheet
    class="d-flex flex-wrap pa-2"
    elevation="0"
    color="white"
  >
    <v-sheet
      v-for="(option, index) in options" 
      :key="index" 
    >
      <v-btn
        outlined
        class='ma-2'
        v-if="option.label" 
        @click="setTransform(currentTransform, option)"
        color='accent'
      >
        <span class="headline">{{option.label}}</span>
      </v-btn>
    </v-sheet>
  </v-sheet>
</div>
</template>