import player from './player'

const setEnvironment = function (settings, init) {
  if (player.api) {
    player.api.setEnvironment(settings, function (err) {
      if (err) {
        console.log(err)
      } else {
        if (init === true) {
          let newSettings = Object.assign({}, settings)
          delete newSettings.uid
          player.api.setEnvironment(newSettings, function (err) {
            getEnvironment()
          })
        }
      }
    })
  }
}

const setBackground = function (settings, callback) {
  if (player.api) {
    player.api.setBackground(settings, function () {
      if (callback != null) callback()
    })
  }
}

const getEnvironment = function () {
  if (player.api) {
    player.api.getEnvironment(function (err, state) {
      if (err) {
        console.log(err)
      }
    })
  }
}

window.configuratorapi.getEnvironment = getEnvironment
window.configuratorapi.setEnvironment = setEnvironment

export default { setEnvironment, getEnvironment, setBackground }
