import _base from './_base-module'

const state = {
  all: [],
  current: '',
  show: true
}

const getters = {
  ..._base.getters,
  getCurrent (state) {
    return state.all.find(item => item.name === state.current)
  },  
  getShow (state) {
    return state.show
  },  
}

const actions = {
  fetchData ({ commit }, {scene}) {
    commit('clear')

    for (let annotation of scene.annotations) {
      commit('addOption', annotation)
      commit('updateCurrent', {name: annotation.name})
    }
    
  },
  setCurrent ({ commit }, {name}) {
    commit('updateCurrent', {name: name})
  },  
  setShow ({ commit }, {show}) {
    commit('updateShow', {show: show})
  }, 
}

const mutations = {
  ..._base.mutations,
  addOption (state, theOption) {
    theOption.show = false
    state.all.push(theOption)
  },
  clear (state) {
    state.all = []
  },
  setVisible (state, payload) {
    let newState = []
    for (let a of state.all) {
      if (a.name === payload.annotationName) {
        a.show = payload.visibility
      } else {
        a.show = false
      }
      newState.push(a)
    }
    state.all = newState
  },
  updateCurrent (state, payload) {
    state.current = payload.name
  },
  updateShow (state, payload) {
    state.show = payload.show
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
