<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'presetgroupoption',
  components: {
  },
  props: {
    presetgroup: Object,
    option: Object,
  },
  data: () => ({
  }),
  computed: {
    isCurrent () {
      return this.presetgroup.visible === this.option.name
    }

  },
  methods: {
    setPresetOption (presetgroup, option) {
      this.$store.dispatch('presetgroup/updateSelectedGroup', {group: presetgroup, option: option})
      if (option.theme === "dark") {
        this.$vuetify.theme.dark = true
      }
      if (option.theme === "light") {
        this.$vuetify.theme.dark = false
      }
      configure.SetPresetGroup(presetgroup, option)
    }
  }  
}
</script>

<template>
  <v-list-item 
    @click="setPresetOption(presetgroup, option)"
  >
    <v-list-item-title 
      v-text="option.title"
    ></v-list-item-title>

    <v-avatar 
      v-if="option.swatch" 
      :style="option.swatch" 
      tile
    >
    </v-avatar>

    <v-list-item-icon class="my-2" v-if="isCurrent">
      <v-icon color="accent">mdi-check-circle</v-icon>
    </v-list-item-icon>    
              
    <v-list-item-icon class="my-2" v-else>
      <v-icon color="cardaccent">mdi-circle-outline</v-icon>
    </v-list-item-icon>              
  </v-list-item>
</template>