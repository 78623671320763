<template>
  <div>
    <v-tabs 
      color="accent" 
      background-color="cardaccent"
      v-model="tab" 
      grow 
      show-arrows 
      center-active 
      centered
      hide-slider
      v-if="loaded"
    >
      <v-tab v-for="(item, index) in menu" :key="index" @click="openBottomNav">
        {{ item.title }}
      </v-tab>

      <v-tab-item 
        v-for="(item, index) in menu" 
        :key="index"
        :menu="item"
      >
        <menuOptions 
          :menu="item"
        />        
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import menuOptions from '../menu-options'

  export default {
    components: {
    menuOptions,
    },
    computed: {
      menu () {
        return this.$store.getters['menu/getAll']
      },
      loaded () {
        return this.$store.getters['scenes/getsceneLoaded']
      },      
    },     
    data() {
      return {
        tab: 0,
      };
    },
    methods: {
      openBottomNav () {
        this.$store.dispatch('ui/setBottomnav', {isOpen: true})
      }
    }
  };
</script>