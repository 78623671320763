<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'environmentslider',
  components: {
  },
  data: () => ({
  }),  
  props: {
    optionname: String,
    optiontitle: String,
    environment: Object,
    min: Number,
    max: Number,
    step: Number,
    currentOption: Object,
  },
  computed: {
    value: {
      get: function () {
        return this.currentOption.payload.environment.payload[this.optionname]
      },
      set: function (newValue) {
        this.$store.dispatch('studiogroup/setEnvironmentOptionPayloadProperty', {environment: this.environment, option: this.currentOption, property: this.optionname, value: newValue})
        const environmentObject = {}
        environmentObject[this.optionname] = newValue
        configure.SetEnvironment(environmentObject)
      }
    },
  },
  methods: {
  }  
}
</script>

<template>
  <v-slider
    :label="optiontitle"
    hide-details
    :min=min
    :max=max
    :step=step
    v-model="value"
    class="black--text ml-3"
  >
    <template v-slot:append>
      <v-text-field
        v-model="value"
        class="mt-0 pt-0"
        type="number"
        style="width: 60px"
        hide-details
        single-line
      ></v-text-field>
    </template>    
  </v-slider>          
</template>