import Sketchfab from '@sketchfab/viewer-api'
import materials from "./materials";
import textures from "./textures";
import scene from "./scene";
import init from  "../configurator/init"
import configure from  "../configurator/configure"
// import playersettings from "data/playersettings";
import store from "../../store";
import sensar from "../project/sensar"
import tavannes from '../project/tavannes';
import nma from '../project/nma';

let sketchfabApi

const getClient = function (elementId) {
  const iframe = document.getElementById(elementId)
  const version = '1.10.4'
  return new Sketchfab(version, iframe)
}

const loadScene = function (sceneId, playerSettings, elementId, onSuccess) {
  let client = getClient(elementId)

  client.init(sceneId, {
    success: onSuccess,
    error: function (error) {
      console.log(error)
    },
    autospin: 0.0,
    preload: 0,
    // transparent: playerSettings.transparent,
    camera: 1, // skips the entry animation, we have more control by setting the viewer position manually
    //  blending: 0,
    ui_stop: 0,
    ui_infos: 0, // need biz account
    ui_controls: 0, // need biz account
    ui_watermark: 0,
    scrollwheel: 1,
    graph_optimizer: 0,
    merge_materials: 0,
    ...playerSettings
  })

}

const playerSuccess = function (api) {
  sketchfabApi = api
  sketchfabApi.load()
  sketchfabApi.start()

  sketchfabApi.addEventListener('viewerready', function () {
    initViewer()
  })
  api.addEventListener('click', function (info) {
    console.log('Mouseclick', info)
  }, { pick: 'fast' }) // use slow when the model is high-res or animated
  api.addEventListener('camerastart', function () {
    store.dispatch('ui/cameraStart')
  })
  api.addEventListener('camerastop', function () {
    store.dispatch('ui/cameraStop')
  })
}


const initViewer = async function () {
  // get data from the scene for future use
  await textures.storeTextureList();
  await scene.storeSceneGraph();
  await materials.storeMaterialList();

  // set up the viewer
  configure.SetPostProcessing()
  configure.SetBackground()
  configure.SetLighting()
  configure.SetEnvironment()

  // set up initial states
  await init.initMaterials()
  init.initVisibility()
  init.initPresetgroup()
  init.initEnvironment()
  init.initLiquid()
  const currentScene = store.getters['scenes/getCurrentScene']
  init.initViews(currentScene.view)

  // Project specific init functions
  let promises = []
  sensar.initTerrain(promises)
  tavannes.startTime()
  nma.initLights(promises)
  
  Promise.all(promises).then(values => {
    // open the sidebar
    console.log('SCENE LOADED')
    store.dispatch('scenes/setSceneLoaded')
  })  
};

const getApi = function () {
  return sketchfabApi
}

export default {
  get api() {
    return sketchfabApi;
  },  
  getApi, 
  loadScene,
  playerSuccess
}
