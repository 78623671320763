<script>
import menuOptions from './menu-options'

export default {
  name: 'menuSubGroup',
  components: {
    menuOptions
  },  
  props: {
    menu: Object
  },
  computed: {
    icon () {
      return this.menu.icon
    }
  },
  methods: {
  }  
}
</script>

<template>
  <v-list-group sub-group no-action color="primary">
    <v-icon slot="prependIcon" >{{menu.icon}}</v-icon>
    <v-icon slot="appendIcon" >mdi-menu-down</v-icon>
    <template v-slot:activator>
      <v-list-item-title 
        v-text="menu.title"
        class="text-h6 font-weight-light"
      ></v-list-item-title>
    </template>

    <menuOptions :menu="menu"/>  
  </v-list-group>
</template>