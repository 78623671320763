<script>

export default {
  name: 'modelsummary',
  components: {
  },
  computed: {
    scenes () {
      return this.$store.getters['scenes/getAll']
    },
    ui () {
      return this.$store.getters['ui/getState']
    },
    dialog () {
      return this.$store.getters['ui/getItemByName']('commercialbar')
    },
    commercialbar: {
      get: function () { return this.$store.state.ui.commercialbar },
      set: function (newValue) { this.$store.dispatch('ui/setCommercialbar', {value: newValue}) }
    },      
    screenshot() {
      return this.$store.state.ui.screenshot
    },    
    currentscene () {
      return this.$store.getters['scenes/getCurrentScene']
    },
    sceneurl () {
      return `${process.env.BASE_URL}#/?scene=${this.currentscene.sceneuid}`
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    width () {
      if (this.mobile) {
        return '100%'
      } else {
        return '400px'
      }
    }
  },
  mounted () {
  },
  data: () => ({
    snackbar: false,
  }),
  methods: {
    setCommercialbar () {
      this.$store.dispatch('ui/setCommercialbar', {value: false})
    },
    clickUrl () {
      navigator.clipboard.writeText(this.sceneurl);
      this.snackbar = true
    }

  }
}
</script>

<template>
  <!-- <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  > -->
  <v-navigation-drawer
    v-model="commercialbar"
    absolute
    temporary
    :width="width"
  >  
    <v-card
      class="overflow-x-hidden fill-height"
      tile
      color="card"
    >
      <v-toolbar
        color="cardaccent2"
        class="px-4"
      >
        <v-btn
          color="card"
          fab
          small
          depressed
          @click="setCommercialbar"
          class="ml-n2"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>  

        <div class="ml-3">
          <div class="text-truncate text-h5 font-weight-medium">Summary</div>
        </div>
      </v-toolbar>

      <v-text-field
        v-model="sceneurl"
        filled
        label="Copy URL"
        @click="clickUrl"
        class="mx-2 mt-2"
      ></v-text-field>

      <v-sheet
        color="cardaccent"
        max-height="512px"
        class="ma-4 elevation-4"
        :aspect-ratio="1/1"
      >
        <v-img
          :src=screenshot
        >
        </v-img>
      </v-sheet>

    </v-card>
    
    <v-snackbar
      v-model="snackbar"
      timeout=2000
    >
      URL copied to clipboard
      <template v-slot:action="{ attrs }">
        <v-btn
          color="accent"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>   
  </v-navigation-drawer>  
  <!-- </v-dialog> -->
</template>

<style lang="scss" scoped>
</style>