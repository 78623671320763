<script>
import sceneTools from '@/tools/babylon/scene'
import geometryTools from '@/tools/babylon/geometry'
import cameraTools from '@/tools/babylon/camera'
import lightingTools from '@/tools/babylon/lighting'
import environmentTools from 'bjs_tool/environment'

import playerTools from '@/tools/sketchfab/player'
import textureTools from '@/tools/sketchfab/textures'
import materialTools from '@/tools/babylon/materials'
// import sceneTools from '@/tools/sketchfab/scene'
import configure from '@/tools/configurator/configure'
import init from '@/tools/configurator/init'
import splash from 'Components/splash'
// import annotationoverlay from '../annotation-overlay'
import store from 'Store'

import '@babylonjs/inspector'

// import '@babylonjs/loaders/glTF';

export default {
  name: 'playerBabylon',
  props: {
    sceneuid: String
  },
  components: {
    // annotationoverlay,
    splash
  },
  data: function () {
    return {
      cameraMoves: false
    }
  },  

  computed: {
    loaded () {
      return this.$store.getters['scenes/getsceneLoaded']
      // return true
    },
    ui () {
      return this.$store.getters['ui/getState']
    },    
    myStyle () {
      const pointer = this.ui.navigatebyannotationonly ? 'none' : 'all'

       return {
        'pointer-events': pointer
      }     
    }
  },
  mounted () {
    // playerTools.init('player__iframe')
    this.loadScene()
  },
  methods: {
    loadScene () {
      // store.commit('model/setProperty', {property: 'menuVisible', value: false})

      let self = this;
      store.dispatch('scenes/setSceneLoading')
      const currentScene = store.getters['scenes/getCurrentScene']
      sceneTools.createScene('player__canvas')
      geometryTools.loadModel(currentScene.scenefile).then(function(scene) {
        console.log('scene', scene)

        cameraTools.buildCamera()

        geometryTools.buildGround('groundtext')
        configure.SetLighting()
        configure.SetEnvironment()
        configure.SetPostProcessing()
        self.initMaterialItems()
        init.initViews(currentScene.view)
        sceneTools.runRenderLoop()
        self.initViewer(self)
        if (store.state.ui.inspector) {
          scene.debugLayer.show({overlay: true})
        }
      })
      // const playerSettings = store.getters['player/getPayload']
      // playerTools.init('player__iframe')
      // playerTools.loadScene(sceneuid, this, this.initViewer, playerSettings, this.modelClickEvent, this.cameraStartEvent, this.cameraStopEvent)
    },
    initViewer: function (scope) {
      scope.initDisplayItems()
      scope.setScene()

      // // open the sidebar
      store.dispatch('scenes/setSceneLoaded')
      store.dispatch('ui/openDrawer')
      // store.commit('model/setProperty', {property: 'menuVisible', value: true})
    },
    setScene () {
      // configure.SetBackground()
    },    

    initDisplayItems () {
      // sceneTools.storeSceneGraph().then(() => {
      //   this.initMaterialItems()
      //   init.initVisibility()
      // }, (error) => {
      //   console.log(error)
      // })
    },
    initMaterialItems () {
      materialTools.setupMaterials(store.state.material.all, environmentTools.getHdr())
      init.initMaterials()


    }        
  }  
}
</script>

<template>
  <v-container class="fill-height" fluid>
    <splash v-if="!loaded"/>
    <!-- <annotationoverlay v-if="loaded" :cameraMoves="cameraMoves"/> -->
    <!-- <iframe class="player" :class="{'iframe__hidden': !loaded}" id="player__iframe" :style="myStyle"></iframe> -->
    <canvas class="player" :class="{'canvas__hidden': !loaded}" id="player__canvas" :style="myStyle"></canvas>
  </v-container>
</template>

<style lang="scss" scoped>
.fill-height {
  padding:0px;
}

.player {
  width: 100%;
  height: 100%;
  border: 0;
  transition: opacity 2.0s ease;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index:0;

  &.canvas__hidden {
    opacity: 0;
  }
}

</style>
