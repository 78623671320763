import scene from './scene'
import {DefaultRenderingPipeline} from '@babylonjs/core';
import {SSAO2RenderingPipeline} from '@babylonjs/core';


const setupPostProcess = function (settings){
    let sc = scene.getScene()

    var defaultPipeline = new DefaultRenderingPipeline(
        "pipeline", // The name of the pipeline
        true, // Do you want HDR textures ?
        sc, // The scene instance
        [sc._activeCamera] // The list of cameras to be attached to
    );
    defaultPipeline.fxaaEnabled = true; //enabling this, disables the ssao2
    
    defaultPipeline.samples = 8;


    // Create SSAO and configure all properties (for the example)
    var ssaoRatio = {
        ssaoRatio: settings.ssaoRatio, // Ratio of the SSAO post-process, in a lower resolution
        blurRatio: settings.blurRatio// Ratio of the combine post-process (combines the SSAO and the scene)
    };

    var ssao = new SSAO2RenderingPipeline("ssao2", sc, ssaoRatio);
    ssao.radius = settings.radius;
    ssao.base = 0.0;
    ssao.totalStrength = 1;
    ssao.expensiveBlur = true;
    ssao.samples = settings.samples;
    //these are important to avoid z-fighting. Tweak them according to the size of the scene
    ssao.maxZ = settings.maxZ;
    ssao.minZAspect = settings.minZAspect;
    
    // Attach camera to the SSAO render pipeline
    sc.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao2", sc._activeCamera);
}

export default { setupPostProcess }
