import libraryData from 'ProjectData/library'
import templateData from 'ProjectData/template'
import _base from './_base-module'

const state = {
  all: [],
  // liquid: [],
  // template: []
}

const getters = {
  ..._base.getters,
  getState (state) {
    return state
  },
  getLiquidByProperty: (state) => (property, value) => {
    return state.all.find(item => item[property] === value)
  }
}

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function assemblePayload(currentPayload, libraryName) {
  let payload = currentPayload
  if (libraryName) {
    let lib = libraryData.liquid.find(item => item.name === libraryName)
    if (lib) {
      payload = {...lib.payload, ...currentPayload}
    }
  }
  return payload
}

function getBasedonLabel(basedonName) {
  if (basedonName) {
    let basedon = templateData.liquid.find(item => item.name === basedonName)
    if (basedon) {
      return basedon.label
    }
  }
  return null
}

function buildLiquidFromMenuoption(option) {
  let liquid = templateData.liquid.find(item => item.name === option.liquid)
  if (liquid === undefined) {
    console.log('Liquid not found in the template data: ', option.liquid)
  }
  liquid.liquidpresets.forEach(liquidpreset => {
    
  
    for (let option of liquidpreset.options) {
      option.id = uuidv4()
      // if (option.icon) {
      //   option.icon = (process.env.BASE_URL + option.icon)
      // }      
    }
    if (liquidpreset.default === undefined) {
      liquidpreset.default = liquidpreset.options[0].name
    }
    console.log('liquidpreset.default', liquidpreset.default)
  });
  return liquid
}

const actions = {
  async fetchData ({ dispatch, commit }, {menu}) {
    commit('clearAll')
    // Search the menu for liquid
    for (let menuitem of menu) {
      if (menuitem.options) {
        for (let option of menuitem.options) {
          if (option.liquid) {
            const liquid = buildLiquidFromMenuoption(option)
            commit('addLiquid', liquid)

            // set up the liquidpresets
            liquid.liquidpresets.forEach(liquidpreset => {
              let currentoption = liquidpreset.options.find(item => item.name === liquidpreset.default)
              commit('updateSelectedPreset', {liquid: liquid, liquidpreset: liquidpreset, option: currentoption})     
              
            });
          }
        }
      }
    }    
    // commit('setTemplate', templateData)

    // const currentScene = projectData.scenes.find(sc => sc.sceneuid === scene.sceneuid)
    // commit('setState', currentScene)
    // commit('setInitialIconSwatch')
    dispatch('initLiquidSets')
  },
  initLiquidSets ({ commit, state }) {
    if (state.all !== undefined) {
      for (let liquid of state.all) {
        for (let preset of liquid.liquidpresets) {
          commit('updateLiquidsets', {liquid: liquid, liquidname: preset.liquidname, payload: preset.options[0].payload})
        }
      }
    }
  },
  setVisibility ({ commit }, {liquid, visible} ) {
    commit('updateVisibility', {liquid: liquid, visible: visible})
  }
}

const mutations = {
  ..._base.mutations,
  clearAll (state) {
    state.all = []
  },
  addLiquid (state, liquid) {
    state.all.push(liquid)
  },
  updateVisibility (state, payload) {
    state.all = state.all.map(liquidItem => {
      if (liquidItem.name === payload.liquid.name) {
        liquidItem.visibility.visible = payload.visible
      } 
      return liquidItem
    })
  },
  updateSelectedPreset (state, payload) {
    state.all = state.all.map(liquidItem => {
      if (liquidItem.name === payload.liquid.name) {
        liquidItem.liquidpresets.forEach(liquidpreset => {
          if (liquidpreset.liquidname === payload.liquidpreset.liquidname) {
            
            liquidpreset.currentTitle = payload.option.title
            liquidpreset.swatch = payload.option.swatch
            // liquidpreset.label = payload.option.label
            liquidpreset.icon = payload.option.icon
            liquidpreset.default = payload.option.name
          }
        });
      } 
      return liquidItem
    })
  },   
  updateCurrentPreset (state, payload) {
    const idx = state.liquid.findIndex(item => item.name === payload.name)
    if (idx > -1) {
      const presetIdx = state.liquid[idx].liquidpresets.findIndex(item => item.liquidname === payload.liquidname)
      if (presetIdx > -1 && payload.option !== null) {
        state.liquid[idx].liquidpresets[presetIdx].currentTitle = state.liquid[idx].liquidpresets[presetIdx].title + ': ' + payload.option.title
        state.liquid[idx].liquidpresets[presetIdx].swatch = payload.option.swatch
        state.liquid[idx].liquidpresets[presetIdx].icon = payload.option.icon
      }
    }
  },
  updateLiquidsets (state, payload) {
    // The liquidsets are objects which stores the liquidname and the currently applied
    // payload. We need to keep a record of these in a separate place because teh user
    // can use presets of manually change these.
    const liquid = state.all.find(item => item.name === payload.liquid.name)
    if (liquid !== undefined) {
      const liquidset = liquid.liquidsets.find(item => item.liquidname === payload.liquidname)
      if (liquidset !== undefined) {
        liquidset.payload = payload.payload
      }
    }
  },
  setState (state, data) {
    if (data.liquid !== undefined) {
      for (let liquidOption of data.liquid) {
        // get a template if any
        if (liquidOption.template !== undefined) {
          const template = state.template.find(tmpl => tmpl.name === liquidOption.template)
          if (template !== undefined) {
            Object.assign(liquidOption, template)
          }
        }
      }
    }
    state.liquid = data.liquid
  },
  setInitialIconSwatch (state) {
    if (state.liquid !== undefined) {
      for (let liquid of state.liquid) {
        for (let liquidpreset of liquid.liquidpresets) {
          if (liquidpreset.options.length > 0) {
            liquidpreset.currentTitle = liquidpreset.title + ': ' + liquidpreset.options[0].title
            liquidpreset.swatch = liquidpreset.options[0].swatch
            liquidpreset.icon = liquidpreset.options[0].icon
          } else {
            liquidpreset.currentTitle = liquidpreset.title
          }
        }
      }
    }
  },
  setTemplate (state, data) {
    state.template = data.template.liquid
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
