<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'optionhookoption',
  components: {
  },
  props: {
    optionhook: Object,
    option: Object
  },
  data: () => ({
  }),
  computed: {
    isCurrent () {
      return this.optionhook.current === this.option.name
    }
  },
  methods: {
    setOptionhook (optionhook, option) {
      this.$store.dispatch('optionhook/updateSelectedOptionhook', {group: optionhook, option: option})

      // REDO ALL PRESETGROUPS WHICH USE THIS HOOK
      let presetgroups = this.$store.state.presetgroup.all
      presetgroups.forEach(presetgroup => {
        const hookedOption = this.$store.getters['presetgroup/getOptionFromGroupByHook'](presetgroup.name, presetgroup.visible, optionhook.name, option.name)
        if (hookedOption) configure.SetPresetGroup(presetgroup, hookedOption)
      });
    }
  }  
}
</script>

<template>
  <v-list-item 
    @click="setOptionhook(optionhook, option)"
  >
    <v-list-item-title 
      v-text="option.title"
    ></v-list-item-title>

    <v-avatar 
      v-if="option.swatch" 
      :style="option.swatch" 
      tile
    >
    </v-avatar>

    <v-list-item-icon class="my-2" v-if="isCurrent">
      <v-icon color="accent">mdi-check-circle</v-icon>
    </v-list-item-icon>    
              
    <v-list-item-icon class="my-2" v-else>
      <v-icon color="cardaccent">mdi-circle-outline</v-icon>
    </v-list-item-icon>              
  </v-list-item>
</template>