class Label {
  constructor(label) {
    if (label) {
    this.name = label.name ? label.name : ''
    this.title = label.title ? label.title : ''
    this.text = label.payload.text ? label.payload.text : null
    this.url = label.payload.url ? label.payload.url : null
    } else {
      this.name = ''
      this.title = 'Unknown label'
      this.text = 'Unknown label text'
      this.url = null
    }
  }
}

export default Label