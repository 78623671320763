import _base from './_base-module'

const state = {
  contactcard: true,
  projecttitle: '',
  headerlogo: '',
  occludedannotationopacity: 0.0,
  movingannotationopacity: 0.2,
  expandactiveannotation: true,
  navigatebyannotationonly: false,    
  background: {
    color: '#5F2E2E',
    type: 'sketchfab'
  },
  theme: 'light',
  contentHeight: '100%',
  bottomnavHeight: '0%',
  projectsdialog: false,
  ctadialog: false,
  cameraMoves: false,
  screenshot: null,
  drawer: false,
  desktopnav: false,
  bottomnav: false,
  commercialbar: false,
  mobilemodelpicker: false,
  desktopmodelpicker: false,
  checkout: false,
  pricing: false,
}

const getters = {
  getState (state) {
    return state
  },
  getDrawer (state) {
    return state.drawer
  },
  getItemByName: (state) => (name) => {
    return state[name]
  }  
}

const actions = {
  fetchData ({ commit }, {ui}) {
    commit('setState', ui)
  },
  setDrawer ({ commit }, {isOpen}) {
    commit('setDrawer', isOpen)
  },
  setBottomnav ({ commit }, {isOpen}) {
    commit('setBottomnav', isOpen)
  },
  setMobileModelpicker ({ commit }, {isOpen}) {
    commit('updateItemByName', {key: 'mobilemodelpicker', value: isOpen})
  },
  setCommercialbar ({ commit }, {value}) {
    commit('updateItemByName', {key: 'commercialbar', value: value})
  },
  setCheckout ({ commit }, {state}) {
    commit('setCheckout', state)
  },
  openDrawer({ commit }) {
    commit('setDrawer', true)
  },
  closeDrawer({ commit }) {
    commit('setDrawer', false)
  },
  cameraStart({commit}) {
    commit('setCameraMoves', true)
  },
  cameraStop({commit}) {
    commit('setCameraMoves', false)
  }
}

const mutations = {
  setState(state, data) {
    state.contactcard = data.contactcard
    state.projecttitle = data.projecttitle
    state.headerlogo = data.headerlogo
    state.occludedannotationopacity = data.occludedannotationopacity
    state.movingannotationopacity = data.movingannotationopacity
    state.expandactiveannotation = data.expandactiveannotation
    state.navigatebyannotationonly = data.navigatebyannotationonly
    state.background = data.background
    state.theme = data.theme
    state.checkout = data.checkout
    state.pricing = data.pricing
  },
  setDrawer(state, data) {
    state.drawer = data
  },
  setBottomnav(state, data) {
    state.bottomnav = data
  },
  setDesktopnav(state, data) {
    state.desktopnav = data
  },
  setDesktopModelpicker(state, data) {
    state.desktopmodelpicker = data
  },
  setMobileModelpicker(state, data) {
    state.mobilemodelpicker = data
  },
  setCheckout(state, data) {
    state.checkout = data
  },
  setCameraMoves(state, data) {
    state.cameraMoves = data
  },
  updateItemByName (state, payload) {
    state[payload.key] = payload.value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
