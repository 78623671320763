<script>
import nmatools from "@/tools/project/nma"
import lightingtools from '@/tools/sketchfab/lighting'

export default {
  name: "lightcontrol",
  components: {},
  props: {
    lightid: Number,
  },
  computed: {
    drawer () {
      return this.$store.state.ui.drawer
    },
    screenstring () {
      if (this.$vuetify.breakpoint.mobile) {
        return 'mobile'
      } else {
        // return 'mobile'
        return 'large'
      }
    },
    bottomnav () {
      return this.$store.state.ui.bottomnav
    },
    showonscreen () {
      // return true
      const isMobile = this.$vuetify.breakpoint.mobile;
      if (this.enabled) {
        if (isMobile) {
          return this.bottomnav
        } else {
          return this.drawer
        }
      } else return false
    },
    nmalight () {
      const light = this.$store.getters['nmalight/getLight'](this.lightid)
      return light
    },
    cameraMoves () {
      return this.$store.state.ui.cameraMoves
    },
    latlon () {
      return this.$store.getters['nmalight/getLightLatlon'](this.lightid)
    },
    // pointstyle () {
    //   return this.$store.getters['nmalight/getPointstyle'](this.lightid)
    // },
    current_light_color: {
      get: function () { return this.color['hex'] },
      set: function (newValue) { this.setColor(newValue) }
    },      
    current_light_intensity: {
      get: function () { return this.intensity },
      set: function (newValue) { this.setIntensity(newValue) }
    },      
  },
  data: function () {
    return {
      light3d: [0,0,0],
      // lightLatLon: [0,0],
      // light2d: [-100, -100],
      shadows: false,
      enabled: false,
      intensity: 0,
      color: {hex: '#FFFFFF'},
      sf_light_matrix: null
    }
  },     
  methods: {
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? [
        parseInt(result[1], 16) / 256,
        parseInt(result[2], 16) / 256,
        parseInt(result[3], 16) / 256
       ] : null;
    },
    ColorToHex(color) {
      const hexadecimal = (Math.round(color * 255)).toString(16);
      return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
    },
    rgbToHex(rgb) {
      return `#${this.ColorToHex(rgb[0])}${this.ColorToHex(rgb[1])}${this.ColorToHex(rgb[2])}`;
    },
    inputLat (value) {
      this.setLightLatLon([value, this.latlon[1]])
    },
    inputLon (value) {
      this.setLightLatLon([this.latlon[0], value])
    },
    changeLatlon () {
      this.$store.dispatch('nmalight/setLightmoves', {value: false})
    },
    startLonLatSlider () {
      lightingtools.getLight(this.lightid)
      .then(light => {
        this.sf_light_matrix = Object.assign({}, light.matrix)
        this.$store.dispatch('nmalight/setLightmoves', {value: true})
      })
    },
    setLightLatLon (latlon) {
      this.sf_light_matrix = nmatools.setLightMatrixFromLatLon(latlon)
      this.light3d = [this.sf_light_matrix[12], this.sf_light_matrix[13], this.sf_light_matrix[14]]
      lightingtools.setLight(this.lightid, {matrix: this.sf_light_matrix})
      this.$store.dispatch('nmalight/setLight3d', {id: this.lightid, coord: this.light3d})
      this.$store.dispatch('nmalight/setLightLatLon', {id: this.lightid, coord: latlon})
    },
    setShadows () {
      lightingtools.setLight(this.lightid, {shadowEnabled: this.shadows})
    },
    setEnabled () {
      lightingtools.getLight(this.lightid)
      .then(light => {
        light.enabled = this.enabled
        this.$store.dispatch('nmalight/setLight', {id: this.lightid, light: light})
        lightingtools.setLight(this.lightid, {enabled: this.enabled})
      })
    },
    setIntensity (intensity) {
      this.intensity = intensity
      lightingtools.setLight(this.lightid, {intensity: intensity})
    },
    setColor (color) {
      this.color['hex'] = color
      lightingtools.setLight(this.lightid, {color: this.hexToRgb(color)})
    },
  },
  mounted() {
    // the div with the 
    // const nmalightwrapper = document.getElementById('nmalightwrapper')
    // const point = document.getElementById(`nmapoint${this.screenstring}${this.lightid}`)
    // nmalightwrapper.appendChild(point)
    // const line = document.getElementById(`nmaline${this.screenstring}${this.lightid}`)
    // nmalightwrapper.appendChild(line)

    const light = this.$store.getters['nmalight/getLight'](this.lightid)
    const lightLatLon = nmatools.getLatLonFromPos([light.matrix[12], light.matrix[13], light.matrix[14]])
    this.$store.dispatch('nmalight/setLightLatLon', {id: this.lightid, coord: lightLatLon})

    this.light3d = [light.matrix[12], light.matrix[13], light.matrix[14]]
    this.intensity = light.intensity
    this.shadows = light.shadowEnabled
    this.enabled = light.enabled
    this.color['hex'] = this.rgbToHex(light.color)
    
  }
};
</script>

<template>
<div>
  <v-card flat tile>
    <div class="d-flex mt-4">
      <v-switch class="pl-4 mt-0 pt-0" dense inset v-model="enabled" label="Enabled" @change="setEnabled"></v-switch>
      <v-switch class="pl-4 mt-0 pt-0" dense inset :disabled="!enabled" v-model="shadows" label="Shadows" @change="setShadows"></v-switch>
    </div>

    <v-slider
      class="black--text mx-3"
      hide-details
      label="Intensity"
      min="0"
      max="5"
      step="0.1"
      v-model="current_light_intensity"
      :disabled="!enabled"
    >
      <template v-slot:append>
        <v-text-field
          v-model="current_light_intensity"
          class="mt-0 pt-0"
          hide-details
          single-line
          readonly
          type="string"
          style="width: 40px"
        ></v-text-field>
      </template>
    </v-slider>

    <v-slider
      class="black--text mx-3"
      hide-details
      label="Lat"
      step="1"
      min="-90"
      max="90"
      :value="latlon[0]"
      @input="inputLat"
      @start="startLonLatSlider"
      @change="changeLatlon"
      :disabled="!enabled"
    >
      <template v-slot:append>
        <v-text-field
          :value="latlon[0]"
          class="mt-0 pt-0"
          hide-details
          single-line
          readonly
          type="string"
          style="width: 40px"
        ></v-text-field>
      </template>
    </v-slider>

    <v-slider
      class="black--text mx-3"
      hide-details
      label="Lon"
      step="1"
      min="-180"
      max="180"
      :value="latlon[1]"
      @input="inputLon"
      @start="startLonLatSlider"
      @change="changeLatlon"
      :disabled="!enabled"
    >
      <template v-slot:append>
        <v-text-field
          :value="latlon[1]"
          class="mt-0 pt-0"
          hide-details
          single-line
          readonly
          type="string"
          style="width: 40px"
        ></v-text-field>
      </template>
    </v-slider>

    <v-color-picker
      class="ml-4"
      dot-size="25"
      swatches-max-height="200"
      v-model="current_light_color"
      hide-inputs
      :disabled="!enabled"
    ></v-color-picker>    
  </v-card>
</div>
</template>

<style lang="scss">


</style>
