<script>
import configure from '@/tools/configurator/configure'
import optionhookmenu from './optionhook-menu'

export default {
  name: 'optionhook',
  components: {
    optionhookmenu
  },
  props: {
    optionname: String
  },
  data: () => ({
    expanded: null
  }),
  computed: {
    theGroup () {
      return this.$store.getters['optionhook/getItemByName'](this.optionname)
    },
    icon () {
      if (this.theGroup.icon) {
        return (process.env.BASE_URL + this.theGroup.icon)
      } else {
        return null
      }
    },    

  },
  methods: {
    highlight () {
      // just adding the click event adds a highlight to a menu item
    }
  },
  mounted () {
    if (this.theGroup?.expand) {
      this.expanded = true
    }
  },   
}
</script>

<template>
  <v-list-group
    v-if="theGroup && theGroup.options.length > 1"
    color='accent'
    v-model="expanded"
    
  >
    <template v-slot:activator>
      <v-list-item-avatar 
        v-if="theGroup.swatch" 
        :color="theGroup.swatch" 
        class="elevation-4"
        left
      />
      <v-list-item-avatar v-if="icon" class="elevation-4">
        <v-img :src="icon"/>
      </v-list-item-avatar>      

      <v-list-item-content two-line>
          <v-list-item-title v-text="theGroup.title"></v-list-item-title>
          <v-list-item-subtitle>{{ theGroup.currentTitle }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
      <v-divider></v-divider>
      <optionhookmenu :optionhook="theGroup"/>

  </v-list-group>

</template>