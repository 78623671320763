const template = {
  optionhook: [
    {
      title: 'Drivetrain speeds',
      name: 'speeds',
      current: '7-speed',
      expand: true,
      options: [
        {
          name: '1-speed',
          title: 'One Speed'
        },
        {
          name: '7-speed',
          title: 'Seven Speed'
        }
      ]
    },
    {
      title: 'Wheels',
      name: 'wheels',
      current: 'wheel-m',
      options: [
        {
          name: 'wheel-m',
          title: 'Medium wheel'
        },
        {
          name: 'wheel-l',
          title: 'Large wheel'
        }
      ]
    }

  ],
  presetgroup: [
    {
      title: 'Styles',
      name: 'styles',
      type: 'group',
      visible: 'steel-black',
      expand: false,
      options: [
        {
          name: 'steel-black',
          title: 'Indiana Jones',
          theme: 'light',
          options: [
            {material: 'case', visible: 'titanium'},
            {material: 'hands', visible: 'steel'},
            {material: 'gaskets', visible: 'red'},
            {material: 'accents', visible: 'white'},
            {material: 'dial', visible: 'black'},
            {material: 'wristband', visible: 'leather_brown'},
            {environment: 'day'},
            {postprocessing: 'regular'},
            {background: 'light'},
            {lighting: 'on'},
          ]
        },
        {
          name: 'polar',
          title: 'Polar',
          theme: 'light',
          options: [
            {material: 'case', visible: 'steel_black'},
            {material: 'hands', visible: 'blue'},
            {material: 'gaskets', visible: 'blue'},
            {material: 'accents', visible: 'white'},
            {material: 'dial', visible: 'blue'},
            {material: 'wristband', visible: 'nato_blue'},
            {environment: 'day'},
            {postprocessing: 'regular'},
            {background: 'white'},
            {lighting: 'on'},
          ]
        },
        {
          name: 'michaelbay',
          title: 'Michael Bay',
          theme: 'dark',
          options: [
            {material: 'case', visible: 'titanium'},
            {material: 'hands', visible: 'steel'},
            {material: 'gaskets', visible: 'blue'},
            {material: 'accents', visible: 'blue'},
            {material: 'dial', visible: 'orange'},
            {material: 'wristband', visible: 'nato_blue'},
            {environment: 'day'},
            {postprocessing: 'michaelbay'},
            {background: 'michaelbay'},
            {lighting: 'on'},
          ]
        },
        {
          name: 'tomclancy',
          title: 'Tom Clancy',
          theme: 'dark',
          options: [
            {material: 'case', visible: 'steel'},
            {material: 'hands', visible: 'steel'},
            {material: 'gaskets', visible: 'beige'},
            {material: 'accents', visible: 'night'},
            {material: 'dial', visible: 'meteorite'},
            {material: 'wristband', visible: 'nato_black'},
            {environment: 'night'},
            {postprocessing: 'night'},
            {background: 'night'},
            {lighting: 'off'},
          ]
        },
      ]
    },
    {
      title: 'Wheels',
      name: 'wheels',
      type: 'group',
      visible: 'wheel',
      options: [
        {
          name: 'wheel',
          title: 'Medium wheels',
          optionhook: {name: 'wheels', option: 'wheel-m'},
          options: [
            {visibilitygroup: 'wheel', visible: 'medium'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'groundplane', default: 'medium'}
          ]
        },
        {
          name: 'wheel',
          title: 'Large wheels',
          optionhook: {name: 'wheels', option: 'wheel-l'},
          options: [
            {visibilitygroup: 'wheel', visible: 'large'},
            {visibilitysingle: 'hierarchyhelpers', visible: false},
            {transform: 'groundplane', default: 'large'}
          ]
        },
      ]
    },
    {
      title: 'Bestsellers',
      name: 'bestsellers',
      type: 'group',
      visible: 'messenger',
      expand: true,
      options: [
        {
          name: 'messenger',
          title: 'Messenger',
          options: [
            {optionhook: 'speeds', current: '7-speed'},
            {optionhook: 'wheels', current: 'wheel-m'},
            {presetgroup: 'wheels', visible: 'wheel'},
            {presetgroup: 'handles', visible: 'mes'},
            {presetgroup: 'fenders', visible: 'none'},
            {visibilitygroup: 'lc', visible: 'none'},
            {visibilitygroup: 'fc', visible: 'none'},  
            
            {visibilitygroup: 'saddle', visible: 'com-g'},
            {visibilitygroup: 'pedals', visible: 'spo'},
            {visibilitysingle: 'chaincover', visible: false},
            {material: 'cover', visible:'Carbon'},

          ]
        },
        {
          name: 'cruiser',
          title: 'Cruiser',
          options: [
            {optionhook: 'wheels', current: 'wheel-l'},
            {optionhook: 'speeds', current: '1-speed'},
            {presetgroup: 'wheels', visible: 'wheel'},
            {presetgroup: 'handles', visible: 'cru'},
            
            {visibilitygroup: 'saddle', visible: 'cru'},
            {visibilitygroup: 'pedals', visible: 'cru'},
            {visibilitysingle: 'chaincover', visible: true},
            {presetgroup: 'fenders', visible: 'nol'},
            {visibilitygroup: 'lc', visible: 'none'},
            {visibilitygroup: 'fc', visible: 'none'},            
            {material: 'cover', visible:'Falls'},
          ]
        },
        {
          name: 'school',
          title: 'School',
          options: [
            {optionhook: 'wheels', current: 'wheel-m'},
            {optionhook: 'speeds', current: '7-speed'},

            {presetgroup: 'wheels', visible: 'wheel'},
            {presetgroup: 'handles', visible: 'nos'},
            {visibilitygroup: 'saddle', visible: 'com-g-white'},
            {visibilitygroup: 'pedals', visible: 'reg'},
            {visibilitysingle: 'chaincover', visible: true},
            {presetgroup: 'fenders', visible: 'lug'},
            {visibilitygroup: 'lc', visible: 'lcs'},
            {visibilitygroup: 'fc', visible: 'none'},
            {material: 'cover', visible:'Plum'},
          ]
        },
        {
          name: 'mule',
          title: 'Mule',
          options: [
            {optionhook: 'wheels', current: 'wheel-m'},
            {optionhook: 'speeds', current: '7-speed'},

            {presetgroup: 'wheels', visible: 'wheel'},
            {presetgroup: 'handles', visible: 'tow'},
            {visibilitygroup: 'saddle', visible: 'com-l-black'},
            {visibilitygroup: 'pedals', visible: 'rel'},
            {visibilitysingle: 'chaincover', visible: true},
            {presetgroup: 'fenders', visible: 'lug'},
            {visibilitygroup: 'lc', visible: 'lcu'},
            {visibilitygroup: 'fc', visible: 'acfcv'},
            {material: 'cover', visible:'Tomato'},

            
          ]
        }
      ]
    },
  ],  
  materialoptions: [
    {
      name: 'case',
      title: 'Case',
      type: 'colorway',
      visible: 'steel_black',
      options: [
        {
          name: 'steel_black',
          title: 'Steel Black PVD',
          icon: 'data/tavannes/assets/icons/steel-black-pvd.png',
          payload: [
            {
              scenematerial: ['Steel'],
              basedon: 'carpaint_regular',
              payload: { 
                AlbedoPBR: { color: [40, 40, 40] }, 
                RoughnessPBR: {enable: true, factor: 0.3},
                BumpMap: {enable: true, textureurl: "data/tavannes/assets/textures/rough.jpg", factor: 4.0}
              },
            },

            {
              scenematerial: ['ReflectiveEngraving'],
              basedon: 'dial',
              payload: { 
                AlbedoPBR: { color: [255, 255, 255] } 
              },
            },
            {
              scenematerial: ['BlackEngraving'],
              basedon: 'carpaint_regular',
              payload: { 
                AlbedoPBR: { color: [75, 75, 75] }, 
                RoughnessPBR: {enable: true, factor: 0.23}, 
              },
            },
          ]
        },
        {
          name: 'steel',
          title: 'Steel',
          icon: 'data/tavannes/assets/icons/steel.png',
          payload: [
            {
              scenematerial: ['Steel', ],
              basedon: 'carpaint_regular',
              payload: { 
                AlbedoPBR: { color: [255, 255, 255], factor: 0.75 }, 
                RoughnessPBR: {enable: true, factor: 0.1}, 
              },
            },
            {
              scenematerial: ['ReflectiveEngraving'],
              basedon: 'dial',
              payload: { 
                AlbedoPBR: { color: [40, 40, 40] } 
              },
            },
            {
              scenematerial: ['BlackEngraving'],
              basedon: 'carpaint_regular',
              payload: { 
                AlbedoPBR: { color: [200, 200, 200] }, 
                RoughnessPBR: {enable: true, factor: 0.23}, 
              },
            },
          ]
        },
        {
          name: 'titanium',
          title: 'Titanium',
          icon: 'data/tavannes/assets/icons/titanium.png',
          payload: [
            {
              scenematerial: ['Steel', ],
              basedon: 'carpaint_regular',
              payload: { 
                AlbedoPBR: { color: [255, 245, 230], factor: 0.56 }, 
                RoughnessPBR: {enable: true, factor: 0.28}, 
              },
            },
            {
              scenematerial: ['ReflectiveEngraving'],
              basedon: 'dial',
              payload: { 
                AlbedoPBR: { color: [40, 40, 40] } 
              },
            },
            {
              scenematerial: ['BlackEngraving'],
              basedon: 'carpaint_regular',
              payload: { 
                AlbedoPBR: { color: [200, 200, 200] }, 
                RoughnessPBR: {enable: true, factor: 0.23}, 
              },
            },
          ]
        },
        {
          name: 'carbon',
          title: 'Carbon',
          icon: 'data/tavannes/assets/icons/carbon.png',
          payload: [
            {
              scenematerial: ['Steel', ],
              basedon: 'carpaint_regular',
              payload: { 
                AlbedoPBR: { color: [40, 40, 40] }, 
                RoughnessPBR: {enable: true, factor: 0.2}, 
              },
            },
            {
              scenematerial: ['ReflectiveEngraving'],
              basedon: 'dial',
              payload: { 
                AlbedoPBR: { color: [255, 255, 255] } 
              },
            },
            {
              scenematerial: ['BlackEngraving'],
              basedon: 'carpaint_regular',
              payload: { 
                AlbedoPBR: { color: [75, 75, 75] }, 
                RoughnessPBR: {enable: true, factor: 0.43}, 
              },
            },
          ]
        },
      ]
    },
    {
      title: 'Hands',
      name: 'hands',
      type: 'single',
      scenematerial: ['Hand'],
      visible: 'black',
      options: [
        {
          title: 'Black',
          name: 'black',
          basedon: 'carpaint_regular',
          payload: { 
            AlbedoPBR: { color: [40, 40, 40] }, 
            RoughnessPBR: {enable: true, factor: 0.23}, 
          },
          icon: 'data/tavannes/assets/icons/hands-black.png'
        },
        {
          title: 'Steel',
          name: 'steel',
          basedon: 'carpaint_regular',
          payload: { 
            AlbedoPBR: { color: [255, 255, 255], factor: 0.75 }, 
            RoughnessPBR: {enable: true, factor: 0.1}, 
          },
          icon: 'data/tavannes/assets/icons/hands-steel.png'
        },
        {
          title: 'Blue',
          name: 'blue',
          basedon: 'carpaint_regular',
          payload: { 
            AlbedoPBR: { color: [30,54,110] }, 
            RoughnessPBR: {enable: true, factor: 0.43}, 
          },
          icon: 'data/tavannes/assets/icons/hands-blue.png'
        }
      ]
    },
    {
      title: 'Wristband',
      name: 'wristband',
      type: 'colorway',
      visible: 'nato_blackred',
      options: [
        {
          title: 'Nato Black Red',
          name: 'nato_blackred',
          payload: [
            {
              basedon: 'nato_band',
              scenematerial: ['Band_main'],
              payload: { 
                AlbedoPBR: {textureurl: "data/tavannes/assets/textures/Band_nato_black.jpg"}, 
              },
            },
            {
              basedon: 'nato_band',
              scenematerial: ['Band_edge'],
              payload: { 
                AlbedoPBR: {textureurl: "data/tavannes/assets/textures/Band_nato_red.jpg"}, 
              },
            },
          ],
          icon: 'data/tavannes/assets/icons/band-nato-black-red.jpg'
        },  
        {
          title: 'Nato Black',
          name: 'nato_black',
          payload: [
            {
              basedon: 'nato_band',
              scenematerial: ['Band_main', 'Band_edge'],
              payload: { 
                AlbedoPBR: {textureurl: "data/tavannes/assets/textures/Band_nato_black.jpg"}, 
              },
            },
          ],
          icon: 'data/tavannes/assets/icons/band-nato-black.jpg'
        },  
        {
          title: 'Nato Blue',
          name: 'nato_blue',
          payload: [
            {
              basedon: 'nato_band',
              scenematerial: ['Band_main', 'Band_edge'],
              payload: { 
                AlbedoPBR: {textureurl: "data/tavannes/assets/textures/Band_nato_blue.jpg"}, 
              },
            },
          ],
          icon: 'data/tavannes/assets/icons/band-nato-blue.jpg'
        },  
        {
          title: 'Nato Red',
          name: 'nato_red',
          payload: [
            {
              basedon: 'nato_band',
              scenematerial: ['Band_main', 'Band_edge'],
              payload: { 
                AlbedoPBR: {textureurl: "data/tavannes/assets/textures/Band_nato_red.jpg"}, 
              },
            },
          ],
          icon: 'data/tavannes/assets/icons/band-nato-red.jpg'
        },  
        {
          title: 'Nato Orange',
          name: 'nato_orange',
          payload: [
            {
              basedon: 'nato_band',
              scenematerial: ['Band_main', 'Band_edge'],
              payload: { 
                AlbedoPBR: {textureurl: "data/tavannes/assets/textures/Band_nato_orange.jpg"}, 
              },
            },
          ],
          icon: 'data/tavannes/assets/icons/band-nato-orange.jpg'
        },  
        {
          title: 'Leather Black',
          name: 'leather_black',
          payload: [
            {
              basedon: 'leather_band',
              scenematerial: ['Band_main', 'Band_edge'],
              payload: { 
                AlbedoPBR: {textureurl: "data/tavannes/assets/textures/Leather_black.jpg"}, 
              },
            },
          ],
          icon: 'data/tavannes/assets/icons/leather-black.jpg'
        },  
        {
          title: 'Leather Blue',
          name: 'leather_blue',
          payload: [
            {
              basedon: 'leather_band',
              scenematerial: ['Band_main', 'Band_edge'],
              payload: { 
                AlbedoPBR: {textureurl: "data/tavannes/assets/textures/Leather_blue.jpg"}, 
              },
            },
          ],
          icon: 'data/tavannes/assets/icons/leather-blue.jpg'
        },  
        {
          title: 'Leather Orange',
          name: 'leather_orange',
          payload: [
            {
              basedon: 'leather_band',
              scenematerial: ['Band_main', 'Band_edge'],
              payload: { 
                AlbedoPBR: {textureurl: "data/tavannes/assets/textures/Leather_orange.jpg"}, 
              },
            },
          ],
          icon: 'data/tavannes/assets/icons/leather-orange.jpg'
        },  
        {
          title: 'Leather Brown',
          name: 'leather_brown',
          payload: [
            {
              basedon: 'leather_band',
              scenematerial: ['Band_main', 'Band_edge'],
              payload: { 
                AlbedoPBR: {textureurl: "data/tavannes/assets/textures/Leather_brown.jpg"}, 
              },
            },
          ],
          icon: 'data/tavannes/assets/icons/leather-brown.jpg'
        },  
      ]
    },
    {
      title: 'Gaskets',
      name: 'gaskets',
      type: 'single',
      scenematerial: ['Gasket'],
      visible: 'red',
      options: [
        {
          title: 'Red',
          name: 'red',
          basedon: 'gasket',
          payload: { AlbedoPBR: { color: [213,24,31] }, },
          icon: 'data/tavannes/assets/icons/gasket-red.png'
        },
        {
          title: 'Blue',
          name: 'blue',
          basedon: 'gasket',
          payload: { AlbedoPBR: { color: [34,27,88] }, },
          icon: 'data/tavannes/assets/icons/gasket-blue.png'
        },
        {
          title: 'Orange',
          name: 'orange',
          basedon: 'gasket',
          payload: { AlbedoPBR: { color: [212,128,33] }, },
          icon: 'data/tavannes/assets/icons/gasket-orange.png'
        },
        {
          title: 'Beige',
          name: 'beige',
          basedon: 'gasket',
          payload: { AlbedoPBR: { color: [174,150,128] }, },
          icon: 'data/tavannes/assets/icons/gasket-beige.png'
        },
        {
          title: 'White',
          name: 'white',
          basedon: 'gasket',
          payload: { AlbedoPBR: { color: [255,255,255] }, },
          icon: 'data/tavannes/assets/icons/gasket-white.png'
        },
        {
          title: 'Black',
          name: 'black',
          basedon: 'gasket',
          payload: { AlbedoPBR: { color: [0,0,0] }, },
          icon: 'data/tavannes/assets/icons/gasket-black.png'
        },
        {
          title: 'Grey',
          name: 'grey',
          basedon: 'gasket',
          payload: { AlbedoPBR: { color: [97,97,99] }, },
          icon: 'data/tavannes/assets/icons/gasket-grey.png'
        },
      ]
    },
    {
      title: 'Accents',
      name: 'accents',
      type: 'single',
      scenematerial: ['Accents'],
      visible: 'white',
      options: [
        {
          title: 'Blue',
          name: 'blue',
          basedon: 'dial',
          payload: { AlbedoPBR: { color: [63,104,154] }, RoughnessPBR: {factor: 0.5}},
          icon: 'data/tavannes/assets/icons/accents-blue.png'
        },
        {
          title: 'Beige',
          name: 'beige',
          basedon: 'dial',
          payload: { AlbedoPBR: { color: [195,163,140] }, RoughnessPBR: {factor: 0.5}},
          icon: 'data/tavannes/assets/icons/accents-beige.png'
        },
        {
          title: 'White',
          name: 'white',
          basedon: 'dial',
          payload: { AlbedoPBR: { color: [255,255,255] }, RoughnessPBR: {factor: 0.5}},
          icon: 'data/tavannes/assets/icons/accents-white.png'
        },
        {
          title: 'Night',
          name: 'night',
          basedon: 'dial',
          payload: { 
            AlbedoPBR: { color: [166,234,34] }, 
            RoughnessPBR: {factor: 0.5},
            EmitColor: {enable: true, color: [166,234,34], factor: 1.8 }
          },
          icon: 'data/tavannes/assets/icons/accents-white.png'
        },
      ]
    },
    {
      title: 'Dial',
      name: 'dial',
      type: 'colorway',
      visible: 'black',
      options: [
        {
          title: 'Black',
          name: 'black',
          payload: [
            {
              basedon: 'dial',
              scenematerial: ['Dial_text', 'Dial_text_accent'],
              payload: { AlbedoPBR: { color: [255,255,255] }, },
            },
            {
              basedon: 'dial',
              scenematerial: ['Dial_relief'],
              payload: { AlbedoPBR: { color: [0,0,0] }, RoughnessPBR: {factor: 0.2}, SpecularF0: {factor: 0.8},},
            },
            {
              basedon: 'dial',
              scenematerial: ['Dial_plate'],
              payload: { AlbedoPBR: { color: [0,0,0] }},
            },
          ],
          icon: 'data/tavannes/assets/icons/dial-black.png'
        },  
        {
          title: 'Blue',
          name: 'blue',
          payload: [
            {
              basedon: 'dial',
              scenematerial: ['Dial_text', 'Dial_text_accent'],
              payload: { AlbedoPBR: { color: [255,255,255] }, },
            },
            {
              basedon: 'dial',
              scenematerial: ['Dial_relief'],
              payload: { AlbedoPBR: { color: [63,104,154] }, RoughnessPBR: {factor: 0.2}, SpecularF0: {factor: 0.8},},
            },
            {
              basedon: 'dial',
              scenematerial: ['Dial_plate'],
              payload: { AlbedoPBR: { color: [44,51,96] }},
            },
          ],
          icon: 'data/tavannes/assets/icons/dial-blue.png'
        },  
        {
          title: 'Orange',
          name: 'orange',
          payload: [
            {
              basedon: 'dial',
              scenematerial: ['Dial_text', 'Dial_text_accent'],
              payload: { AlbedoPBR: { color: [255,255,255] }, },
            },
            {
              basedon: 'dial',
              scenematerial: ['Dial_relief'],
              payload: { AlbedoPBR: { color: [248,136,46] }, RoughnessPBR: {factor: 0.2}, SpecularF0: {factor: 0.8},},
            },
            {
              basedon: 'dial',
              scenematerial: ['Dial_plate'],
              payload: { AlbedoPBR: { color: [248,136,46] }},
            },
          ],
          icon: 'data/tavannes/assets/icons/dial-orange.png'
        },  
        {
          title: 'White',
          name: 'white',
          payload: [
            {
              basedon: 'dial',
              scenematerial: ['Dial_text', 'Dial_text_accent'],
              payload: { AlbedoPBR: { color: [0,0,0] }, },
            },
            {
              basedon: 'dial',
              scenematerial: ['Dial_relief'],
              payload: { AlbedoPBR: { color: [200,200,200] }, RoughnessPBR: {factor: 0.2}, SpecularF0: {factor: 0.8},},
            },
            {
              basedon: 'dial',
              scenematerial: ['Dial_plate'],
              payload: { AlbedoPBR: { color: [255,255,255] }},
            },
          ],
          icon: 'data/tavannes/assets/icons/dial-white.png'
        },  
        {
          title: 'Meteorite',
          name: 'meteorite',
          payload: [
            {
              basedon: 'dial',
              scenematerial: ['Dial_text', 'Dial_text_accent'],
              payload: { AlbedoPBR: { color: [255,255,255] }, },
            },
            {
              basedon: 'dial',
              scenematerial: ['Dial_relief'],
              payload: { 
                AlbedoPBR: { color: [255,255,255], factor: 0.48 },
                MetalnessPBR: {factor: 1.0},
                RoughnessPBR: { factor: 0.35 }, 
                SpecularF0: {factor: 0.8},
                NormalMap: {textureurl: "data/tavannes/assets/textures/iron-meteorite_norm.jpg", factor: 0.2}
              },
            },
            {
              basedon: 'dial',
              scenematerial: ['Dial_plate'],
              payload: { 
                AlbedoPBR: { color: [255,255,255], factor: 0.48 },
                MetalnessPBR: {factor: 1.0},
                RoughnessPBR: { textureurl: "data/tavannes/assets/textures/iron-meteorite_metl.jpg", factor: 1.0 }, 
                SpecularF0: {factor: 0.8},
                NormalMap: {textureurl: "data/tavannes/assets/textures/iron-meteorite_norm.jpg", factor: 0.2}
              },
            },
          ],
          icon: 'data/tavannes/assets/icons/dial-meteorite.png'
        },  
      ]
    },
   
  ],
  visibilitysingle: [
    {
      title: 'Wristband',
      name: 'wristband',
      type: 'single',
      visible: true,
      objects: [
          'Band_clip',
          'Band_main',
          'Band_main001',
          'Band_side',
          'Band_stitching',
          'Buckle',
      ]
    }
  ],
  visibilitygroup: [
    {
      title: 'Caseback',
      name: 'caseback',
      type: 'group',
      visible: 'black_edition',
      options: [
        {
          name: 'black_edition',
          title: 'Black Edition',
          objects: ['Caseback_1'],
          matchNameExactly: false
        },
        {
          name: 'submarine',
          title: 'Submarine',
          objects: ['Caseback_2'],
          matchNameExactly: false
        }
      ]
    },    
  ],
  transform: [
  ],
  materials: [
    {
      name: 'carpaint_regular',
      library: 'Steel',
      payload: { 
        AlbedoPBR: {color: [200, 200, 200]},
        RoughnessPBR: {factor: 0.1},
        BumpMap: {enable: false}
      },
    },
    {
      name: 'skai_standard',
      library: 'Skai',
      payload: { 
        AlbedoPBR: {color: [200, 200, 200]},
        RoughnessPBR: {factor: 0.1}
      },
    },
    {
      name: 'nato_band',
      payload: { 
        AlbedoPBR: {factor: 0.8},
        RoughnessPBR: {enable: true, factor: 0.6},
        SpecularF0: {factor: 0.15},
        MetalnessPBR:{factor: 0},
        NormalMap: {textureurl: "data/tavannes/assets/textures/Band_nato_norm.png", factor: 4}
      },
    },
    {
      name: 'leather_band',
      payload: { 
        RoughnessPBR: {enable: true, factor: 0.4},
        SpecularF0: {factor: 0.28},
        MetalnessPBR:{factor: 0},
        NormalMap: {textureurl: "data/tavannes/assets/textures/Leather_norm.png", factor: 0.2}
      },
    },
    {
      name: 'gasket',
      payload: {
        RoughnessPBR: {enable: true, factor: 0.23},
        SpecularF0: {factor: 0.3}
      },
    },   
    {
      name: 'dial',
      payload: {
        AlbedoPBR: {color: [200, 200, 200], factor: 1.0},
        GlossinessPBR: {enable: false},
        EmitColor: {enable: false},
        RoughnessPBR: {enable: true, texture: '', textureurl: '', factor: 0.8},
        SpecularF0: {factor: 0.3},
        MetalnessPBR: {factor: 0.0},
        NormalMap: {factor: 0.0, texture: '', textureurl: '', }
      },
    },   
    {
      name: 'mattepaint',
      payload: {
        AlbedoPBR: {factor: 1.0, color: [255, 255, 255]},
        GlossinessPBR: {factor: 0.65},
        SpecularF0: {factor: 0.5}
      },
    },   
  ],
  views: [
    {
      title: 'Start',
      name: 'start',
      payload: {
        eye: [60.99,-71.24,33.04],
        lookat: [1.95,17.31,-10.59],
        fov: 55
      }
    },
    {
      title: 'Front',
      name: 'front',
      payload: {
        eye: [-3.06,-107.33,25.66],
        lookat: [1.95,17.31,-10.59],
        fov: 55
      }
    },
    {
      title: 'Side',
      name: 'side',
      payload: {
        eye: [110.69,13.92,-3.06],
        lookat: [1.95,17.31,-10.59],
        fov: 55
      }
    },
    {
      title: 'Rear',
      name: 'rear',
      payload: {
        eye: [-0.46,88.07,-43.63],
        lookat: [1.95,17.31,-10.59],
        fov: 55
      }
    },
  ],
  player: [
    {
      name: 'tavannes',
      library: 'tavannes',
      payload: {
        orbit_constraint_zoom_in: 60,
        orbit_constraint_zoom_out: 160
      }
    }
  ],
  studiogroup: [
    {
      title: 'Environment',
      name: 'environment',
      visible: 'day',
      options: [
        {
          name: 'day',
          title: 'Day studio24',
          payload: {
            environment: {
              basedon: 'day',
              payload: {}
            },
            lighting: {
              basedon: 'on',
              payload: {}
            },
            background: {
              basedon: 'light',
              payload: {}
            },
            post: {
              basedon: 'regular',
              payload: {}
            },
            theme: 'light'
          },
          swatch: 'rgba(200, 200, 200, 1.0)'
        },
        {
          name: 'night',
          title: 'Night studio24',
          payload: {
            environment: {
              basedon: 'night',
              payload: {}
            },
            lighting: {
              basedon: 'off',
              payload: {}
            },
            background: {
              basedon: 'night',
              payload: {}
            },
            post: {
              basedon: 'night',
              payload: {}
            },
            theme: 'dark'
          },
          swatch: 'rgba(20, 20, 20, 1.0)'
        },
        {
          name: 'michaelbay',
          title: 'Michael Bay',
          payload: {
            environment: {
              basedon: 'day',
              payload: {}
            },
            lighting: {
              basedon: 'on',
              payload: {}
            },
            background: {
              basedon: 'michaelbay',
              payload: {}
            },
            post: {
              basedon: 'michaelbay',
              payload: {}
            },
            theme: 'dark'
          },
          swatch: 'rgba(90, 20, 20, 1.0)'
        },
      ]
    }
  ],
  postprocessing: [
    {
      name: 'regular',
      library: 'basic_ssao',
      payload: {
      }
    },
    {
      name: 'michaelbay',
      library: 'basic_ssao',
      payload: {
        vignetteEnable: true,
        vignetteAmount: 0.4,
        vignetteHardness: 0.7,
        chromaticAberrationEnable: true,
        chromaticAberrationFactor: 0.04,
        toneMappingEnable: true,
        toneMappingMethod: "filmic",
        toneMappingExposure: 1.65,
        toneMappingBrightness: -0.44,
        toneMappingContrast: 0.2,
        toneMappingSaturation: 1.02,
        colorBalanceEnable: true,
        colorBalanceHigh: [0.133, 0, -0.43],
        colorBalanceMid: [-0.1, 0.04, 0.24],
        colorBalanceLow: [0.92, 0.19, -0.62],

      }
    },
    {
      name: 'night',
      library: 'basic_ssao',
      payload: {
        bloomEnable: true,
        bloomThreshold: 0.2,
        bloomFactor: 0.17,
        bloomRadius: 0.65,
        vignetteEnable: true,
        vignetteAmount: 0.7,
        vignetteHardness: 0.6,
        ssrEnable: true,
        ssrFactor: 1,
      }
    },
  ],
  annotations: [
  ],
  labels: [
    {
      name: 'wheel',
      title: 'Wheels',
      payload: {
        text: 'The wheels are made of a strong and chemical resistant compound. The wheels leave no trace on all floor types.\nClean with all purpose cleaner.'
      }
    }
  ],
  environment: [
    {
      name: 'day',
      library: 'studio024',
      payload: {
      }
    },
    {
      name: 'night',
      library: 'studio024',
      payload: {
        enabled: true,
        exposure: 0.2,
        lightIntensity: 0.3,
      }
    },
  ],
  background: [
    {
      name: 'light',
      library: 'white',
      payload: {
        color: [0.9, 0.9, 0.9]
      }
    },
    {
      name: 'white',
      library: 'white',
      payload: {
        color: [0.96, 0.96, 0.98]
      }
    },
    {
      name: 'night',
      library: 'white',
      payload: {
        color: [0.1, 0.08, 0.08],
      }
    },
    {
      name: 'michaelbay',
      library: 'white',
      payload: {
        color: [0.6, 0.7, 0.7],
      }
    },
  ],
  lighting: [
    {
      name: 'on',
      library: null,
      payload: {
        enable: true
      }
    },
    {
      name: 'off',
      library: null,
      payload: {
        enable: false
      }
    },
  ],
}
  
export default template;