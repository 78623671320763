<script>
import projectData from 'ProjectData/project'
import modelpickeritem from './modelpicker-item'
import cardcommercial from 'Components/card-commercial'

export default {
  name: 'modelpicker',
  components: {
    modelpickeritem,
    cardcommercial
  },
  created () {
    this.$store.commit('scenes/init', projectData.scenes)
    // const scenes = this.$store.getters['scenes/getAll']
    this.$store.dispatch('ui/fetchData', {ui: projectData.ui})
  },  
  computed: {
    scenes () {
      return this.$store.getters['scenes/getAll']
    },
    ui () {
      return this.$store.getters['ui/getState']
    }    
  },
  mounted () {
    this.$store.commit('ui/updateItemByName', {key: 'cardcommercialbig', value: true})
  },
  data: () => ({
  })
}
</script>

<template>

  <v-app id="modelpicker">

    <v-app-bar app fixed clipped-left height="96">
      <div class="header">
        <h1 class="display-1 d-block text-truncate">Pick a model</h1>
        <h1 class="subtitle-1 d-block text-truncate">{{ ui.projecttitle }}</h1>
      </div>
    </v-app-bar>

    <v-main>
      <v-sheet class="pa-5 fill-height">
          <v-row>
            <v-col v-for="scene in scenes" :key="scene.id" cols=12 sm=6 md=4 lg=3>
              <modelpickeritem :scene="scene"/>
            </v-col>
            <v-col v-if="ui.contactcard" cols=12 sm=6 md=4 lg=3>
              <cardcommercial/>
            </v-col>
          </v-row>
      </v-sheet>
    </v-main>

  </v-app>
</template>
