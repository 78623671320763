<script>

export default {
  name: 'cardportfolio',
  components: {
  },
  props: {
    project: Object
  },  
  data: () => ({
  }),
  computed: {
    img () {
      const imagePath = require.context('@/assets/', true)
      const fullpath = imagePath(this.project.img, true)
      return fullpath
    },
    imgheight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '400'
        case 'sm': return '380'
        case 'md': return '180'
        case 'lg': return '240'
        case 'xl': return '290'
        default: return '275'
      }
    }    
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<template>
  <v-card 
    outlined
  >
    <v-img
      :src="img"
    >
    </v-img>
    <v-card-title>{{project.title}}</v-card-title>
    <v-card-subtitle>{{project.subtitle}}</v-card-subtitle>
    <v-card-text >{{project.text}}</v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        text
        class="text--secondary"
        v-if="project.url"
        :href="project.url"
        target="_blank"
      >
        Visit site    
      </v-btn>
      <v-btn
        text
        class="text--secondary"
        v-if="project.articleurl"
        :href="project.articleurl"
        target="_blank"
      >
        Read more   
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>

</style>
