<script>
import configure from '@/tools/configurator/configure'
import presetgroupoption from './presetgroup-option'

export default {
  name: 'presetgroupmenu',
  components: {
    presetgroupoption
  },
  props: {
    presetgroup: Object
  },
  data: () => ({
  }),
  computed: {
    theOptions () {
      let self = this
      // THIS CAN BE SIMPLER

      // the list of preset options is fileterd by an optionhook. If there are multiple options with the same name
      // we take a look if there's an optionhook available to figure out which option to show
      let options = this.presetgroup.options
      let myOrderedArray = options.reduce(function (accumulator, currentValue, index) {
        const idx = accumulator.findIndex(item => item.name === currentValue.name)
        if (idx === -1) {
          accumulator.push(currentValue)
        } else {
          // a preset with this name has already collected. Check if there's an optionhook to determine
          // which preset we should use
          if (accumulator[idx].optionhook?.name === currentValue.optionhook?.name) {
            // get the optionhook these options share
            const theHook = self.$store.getters['optionhook/getItemByName'](currentValue.optionhook.name)
            if (theHook.current === currentValue.optionhook.option) {
              accumulator[idx] = currentValue
            }
          }
        }
        return accumulator
      }, [])      
      return myOrderedArray
    },
 
  },
  methods: {
  }  
}
</script>

<template>
  <v-list color="cardaccent2">
    <template v-for="(option, index) in theOptions" >
      <presetgroupoption :key="index" :option="option" :presetgroup="presetgroup"/>
    </template>
  </v-list>  
</template>