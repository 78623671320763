import { render, staticRenderFns } from "./model-summary.vue?vue&type=template&id=53d05b04&scoped=true&"
import script from "./model-summary.vue?vue&type=script&lang=js&"
export * from "./model-summary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53d05b04",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VIcon,VImg,VNavigationDrawer,VSheet,VSnackbar,VTextField,VToolbar})
