<script>
import optionhookoption from './optionhook-option'

export default {
  name: 'optionhookmenu',
  components: {
    optionhookoption
  },
  props: {
    optionhook: Object
  } 
}
</script>

<template>
  <v-list color="cardaccent2">
    <template v-for="(option, index) in optionhook.options">
      <optionhookoption :key="index" :option="option" :optionhook="optionhook"/>
    </template>  
  </v-list>  
</template>