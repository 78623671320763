const project = {
  ui: {
    contactcard: true,
    projecttitle: 'By Klaas Nienhuis',
    headerlogo: 'data/tavannes/assets/tavannes-icon.png',
    occludedannotationopacity: 0.0,
    movingannotationopacity: 0.2,
    expandactiveannotation: true,
    navigatebyannotationonly: false,    
    background: {
      color: '#F2F2F2'
    },
    theme: 'light',
    checkout: true,
    pricing: false,
  },
  scenes: [
    {
      scenetitle: 'Submarine Commander',
      header: 'Header',
      description: 'Description',
      sceneuid: 'c741132f897042efa969634ecc50ec02',
      subheader: 'Configurator by Klaas Nienhuis',
      banner: 'data/tavannes/assets/tavannes-icon.png',
      menu: [
        {
          name: 'stl',
          title: 'Styles',
          icon: 'mdi-palette',
          expand: true,
          options: [
            {presetgroup: 'styles'},
          ]
        },
        {
          name: 'opt',
          title: 'Customize',
          icon: 'mdi-wrench',
          options: [
            {visibilitysingle: 'wristband'},
            {visibilitygroup: 'caseback'},
            {material: 'case'},
            {material: 'hands'},
            {material: 'wristband'},
            {material: 'gaskets'},
            {material: 'accents'},
            {material: 'dial'},
            // {studiogroup: 'environment', hidden: false},
          ]
        },
        {
          name: 'cam',
          title: 'Views',
          icon: 'mdi-eye',
          options: [
            {view: 'start'},
            {view: 'front'},
            {view: 'side'},
            {view: 'rear'},
          ]
        },
      ],

      player: {template: 'tavannes', payload: {}},
      postprocessing: {template: 'regular', payload: {}},
      background: {template: 'light', payload: {}},
      lighting: {template: 'on', payload: {}},
      environment: {template: 'day', payload: {}},
      annotations: []      
    }
  ]
}

export default project;