<script>
import transformtranslatemenu from './transform-translate-menu'
import transformmatchmatrixmenu from './transform-matchmatrix-menu'
import transformrotatemenu from './transform-rotate-menu'

export default {
  name: 'transformoption',
  components: {
    transformtranslatemenu,
    transformmatchmatrixmenu,
    transformrotatemenu
  },
  props: {
    optionname: String
  },
  data: () => ({
    expanded: null
  }),  
  computed: {
    transformoption () {
      return this.$store.getters['transform/getItemByName'](this.optionname)
    },
    isTransformTranslate () {
      return this.transformoption.type === 'translate'
    },    
    isTransformRotate () {
      return this.transformoption.type === 'rotate'
    },    
    isTransformMatchmatrix () {
      return this.transformoption.type === 'matchmatrix'
    },    
    label () {
      if (this.transformoption.label) {
        return this.transformoption.label
      } else {
        return ''
      }
    },
  },
  methods: {
  },
  mounted () {
    if (this.transformoption.expand) this.expanded = true
  }  
}
</script>

<template>
  <v-list-group
    v-if="transformoption && transformoption.options.length > 0"
    color='accent'
    v-model="expanded"
  >
    <template v-slot:activator>
      <v-list-item-avatar 
        v-if="label" 
        class="elevation-4 headline"
        left
      >
        <span class="headline">{{label}}</span>
      </v-list-item-avatar>

      <v-list-item-content two-line>
          <v-list-item-title v-text="transformoption.title"></v-list-item-title>
          <v-list-item-subtitle>{{ transformoption.currentTitle }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <v-divider></v-divider>

    <transformtranslatemenu v-if="isTransformTranslate" :options="transformoption.options" :name="transformoption.name"/>
    <transformmatchmatrixmenu v-if="isTransformMatchmatrix" :options="transformoption.options" :name="transformoption.name"/>
    <transformrotatemenu v-if="isTransformRotate" :options="transformoption.options" :name="transformoption.name"/>

  </v-list-group>
</template>