import _base from './_base-module'
import templateData from 'ProjectData/template'
import libraryData from 'ProjectData/library'
import Environment from '@/classes/Environment'

const state = {
  // library: [],
  // template: [],
  // payload: {},
  all: [],
}

const getters = {
  ..._base.getters
}

// function uuidv4() {
//   return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
//     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
//   );
// }

function assemblePayload(currentPayload) {
  if (currentPayload.environment && currentPayload.environment.basedon) {
    let template = templateData.environment.find(item => item.name === currentPayload.environment.basedon)
    if (template) {
      currentPayload.environment.payload = {...template.payload, ...currentPayload.environment.payload}
    } else {
      console.log("No template found for environment", currentPayload.environment.basedon)
    }
  }
  if (currentPayload.lighting && currentPayload.lighting.basedon) {
    let template = templateData.lighting.find(item => item.name === currentPayload.lighting.basedon)
    if (template) {
      currentPayload.lighting.payload = {...template.payload, ...currentPayload.lighting.payload}
    } else {
      console.log("No template found for lighting", currentPayload.lighting.basedon)
    }
  }
  if (currentPayload.background && currentPayload.background.basedon) {
    let template = templateData.background.find(item => item.name === currentPayload.background.basedon)
    if (template) {
      currentPayload.background.payload = {...template.payload, ...currentPayload.background.payload}
    } else {
      console.log("No template found for background", currentPayload.background.basedon)
    }
  }
  if (currentPayload.post && currentPayload.post.basedon) {
    let template = templateData.postprocessing.find(item => item.name === currentPayload.post.basedon)
    if (template) {
      currentPayload.post.payload = {...template.payload, ...currentPayload.post.payload}
    } else {
      console.log("No template found for postprocessing", currentPayload.post.basedon)
    }
  }
}

function getBasedonSwatch(basedonName) {
  if (basedonName) {
    let basedon = templateData.studiogroup.find(item => item.name === basedonName)
    if (basedon) {
      return basedon.swatch
    }
  }
  return null
}

function buildStudiogroupFromMenuoption(menuOption) {
  let studiogroup = templateData.studiogroup.find(item => item.name === menuOption.studiogroup)

  // if (environment.type === 'static') {
    for (let studiogroupOption of studiogroup.options) {
      studiogroupOption.id = _base.uuidv4()

      assemblePayload(studiogroupOption.payload)
      if (studiogroupOption.icon) {
        studiogroupOption.icon = (process.env.BASE_URL + studiogroupOption.icon)
      }
      studiogroupOption.hidden = false
    }
  // }
  if (menuOption.hidden !== undefined) studiogroup.hidden = menuOption.hidden
  
  if (studiogroup.visible === undefined) {
    studiogroup.visible = studiogroup.options[0].name
  }
  return studiogroup
}

const actions = {
  setCurrentEnvironment ({ commit }, {environment, option}) {
    commit('updateSelectedEnvironment', {environment: environment, option: option})
  },
  setEnvironmentOptionPayloadProperty ({commit}, {environment, option, property, value}) {
    commit('setEnvironmentOptionPayloadProperty', {environment: environment, option: option, property: property, value: value})
  },
  fetchData ({ commit }, {menu}) {
    commit('clearAll')
    const menuOptions = _base.getMenuOptionsOfType(menu, 'studiogroup')
    menuOptions.forEach(menuOption => {
      const studiogroup = buildStudiogroupFromMenuoption(menuOption)
      let currentoption = studiogroup.options.find(item => item.name === studiogroup.visible)
      commit('addEnvironment', studiogroup)
      commit('updateSelectedEnvironment', {environment: studiogroup, option: currentoption})     
    });
  }
}

const mutations = {
  ..._base.mutations,
  clearAll (state) {
    state.all = []
  },
  addEnvironment (state, environment) {
    state.all.push(environment)
  },
  setEnvironmentOptionPayloadProperty (state, payload) {
    state.all = state.all.map(item => {
      if (item.name === payload.environment.name) {
        item.options = item.options.map(option => {
          if (option.name === payload.option.name) {
            option.payload.environment.payload[payload.property] = payload.value
          }
          return option
        })
      } return item
    })
  },
  updateSelectedEnvironment (state, payload) {
    state.all = state.all.map(item => {
      if (item.name === payload.environment.name) {
        item.currentTitle = payload.option.title
        item.swatch = payload.option.swatch
        item.icon = payload.option.icon
        item.visible = payload.option.name
      } return item
    })
  }, 
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
