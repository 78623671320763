const library = {
  materials: [
    {
      name: 'Steel',
      payload: {
        AlbedoPBR: {factor: 1.0, color: [170, 170, 170]},
        MetalnessPBR:{factor: 1},
        RoughnessPBR: {enable: true, factor: 0.15},
        GlossinessPBR: {enable: false},
        ClearCoat: {enable: false},
        SpecularF0: {factor: 0.0}
      }
    },
    {
      name: 'Skai',
      payload: {
        AlbedoPBR: {factor: 1.0, color: [128, 128, 128]},
        GlossinessPBR: {factor: 0.7},
        SpecularF0: {factor: 0.3}
      }
    }    
  ],
  player: [
    {
      name: 'avoidunder',
      payload: {
        orbit_constraint_pitch_down: -0.1,
        orbit_constraint_pitch_up: 1.5,
        orbit_constraint_pan: 0
      }
    },
    {
      name: 'tavannes',
      payload: {
        orbit_constraint_pitch_down: -1.5,
        orbit_constraint_pitch_up: 1.5,
        orbit_constraint_pan: 0,
        transparent: 0
      }
    }
  ],
  postprocessing: [
    {
      name: 'basic_ssao',
      payload: {
        enable: true,
        AR: false,
        bloomEnable: false,
        chromaticAberrationEnable: false,
        colorBalanceEnable: false,
        doDistortionVR: false,
        dofEnable: false,
        grainEnable: false,
        sharpenEnable: false,
        ssrEnable: false,
        taaEnable: true,
        taaTransparent: true,
        toneMappingEnable: false,
        vignetteEnable: false,
        vrFading: false,
        vrStartFade: false,
        webVR: false,
        ssaoBias: 0.2,
        ssaoEnable: true,
        ssaoIntensity: 0.2,
        ssaoRadius: 0.2
      }
    }
  ],
  environment: [
    {
      name: 'lojer',
      payload: {
        enabled: true,
        exposure: 1.8,
        lightIntensity: 0.3,
        rotation: 2.8439038663,
        shadowEnabled: true,
        uid: '9190e8da70694ef3b9d1d0c01541917e'
      }
    },
    {
      name: 'studio024',
      payload: {
        enabled: true,
        exposure: 1.7,
        lightIntensity: 2.5,
        shadowEnabled: true,
        shadowBias: 0.005,
        rotation: 1.31,
        uid: 'b628efe3c1a449d1ade1a6f230319b89',
      }
    },
    {
      name: 'gasgrill',
      payload: {
        enabled: true,
        shadowEnable: true,
        shadowBias: 0.005,
        backgroundExposure: 1.1,
        blur: 0.0,
        uid: 'ebc114da108b47b183e48d96f1026f2c',
        exposure: 1.2,
        lightIntensity: 0.1,
        rotation: 0.75
      }
    }
  ],
  background: [
    {
      name: 'transparent',
      payload: {
        enable: 'color',
        transparent: true
      }
    },
    {
      name: 'white',
      payload: {
        enable: 'color',
        color: [1, 1, 1]
      }
    },
    {
      name: 'lightgrey',
      payload: {
        enable: 'color',
        color: [0.9, 0.9, 0.9]        
      }
    },
    {
      name: 'grey',
      payload: {
        enable: 'color',
        color: [0.22, 0.22, 0.22]        
      }
    }
  ]
}
  
export default library;