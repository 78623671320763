import player from './player'

const setPostProcessing = function (settings, callback) {
  let api = player.getApi()
  if (api) {
    api.setPostProcessing(settings, function () {
      if (callback != null) callback()
    })
  }
}

const getPostProcessing = function () {
  let api = player.getApi()
  if (api) {
    api.getPostProcessing(function (settings) {
      console.log(settings)
    })
  }
}

window.configuratorapi.setPostProcessing = setPostProcessing
window.configuratorapi.getPostProcessing = getPostProcessing

export default { setPostProcessing, getPostProcessing }
