import store from '../../store';
import player from './player'

let cameraTimeout

const setCameraLookAt = function (eye, target, duration) {
  if (duration === null || duration === undefined) duration = 1

  if (player.api) {
    player.api.setCameraConstraints({useZoomConstraints: false}, function(err) {
      player.api.setCameraLookAt(eye, target, duration, function () {
        const playerPayload = store.state.player.payload
        const camConstrains = {}
        camConstrains.useZoomConstraints = playerPayload.orbit_constraint_zoom_in !== null
        camConstrains.usePanConstraints = playerPayload.orbit_constraint_pan === 1
        camConstrains.target = target //turning on the constraints might trigger a residual jog to the camera. This line should prevent that
        clearTimeout(cameraTimeout)
        cameraTimeout = setTimeout(() => {
          player.api.setCameraConstraints(camConstrains, function(err) {
          });
        }, 2200);             
      })
    })
  }
}

const setFov = function (fov) {
  if (fov === null) fov = 45

  return new Promise ((resolve, reject) => {
    if (player.api) {
      player.api.setFov(fov, function () {
        resolve()
      })
    } else reject()
  })
}

const getCamera = function () {

  return new Promise ((resolve, reject) => {
    if (player.api) {
      player.api.getCameraLookAt(function (err, camera) {
        if (err) reject(err)
        resolve(camera)
      })
    } else reject()
  })
}

const getFov = function () {

  return new Promise ((resolve, reject) => {
    if (player.api) {
      player.api.getFov(function (err, angle) {
        if (err) reject(err)
        resolve(angle)
      })
    } else reject()
  })
}

export default { setCameraLookAt, getCamera, setFov, getFov }
