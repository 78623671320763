//SKETCHFAB
import cameraTools from 'sfb_tool/camera'
import transformTools from 'sfb_tool/transform'
import screenshot from 'sfb_tool/screenshot'
import materialTools from 'sfb_tool/materials'
import sceneTools from 'sfb_tool/scene'
import environmentToolsSFB from 'sfb_tool/environment'
import postprocessingToolsSFB from 'sfb_tool/postprocessing'
import lightingToolsSFB from 'sfb_tool/lighting'
import playerTools from 'sfb_tool/player'

//BJS
import environmentToolsBJS from 'bjs_tool/environment'
import postprocessingToolsBJS from 'bjs_tool/post'
import lightingToolsBJS from 'bjs_tool/lighting'
import materialToolsBJS from 'bjs_tool/materials'
import cameraToolsBJS from 'bjs_tool/camera'
import sceneToolsBJS from 'bjs_tool/scene'
import textToolsBJS from 'bjs_tool/text'


import store from '@/store'


const _isBabylon = function () {
  return (store.state.scenes.currentScene.engine === 'babylon')
}

const _isSketchfab = function () {
  return (store.state.scenes.currentScene.engine === null || store.state.scenes.currentScene.engine === undefined || store.state.scenes.currentScene.engine === 'sketchfab')
}

const SetVisibilitySingle = function (isVisible, names, matchNameExactly) {
  if (matchNameExactly === undefined) {
    matchNameExactly = false
  }

  if (_isBabylon()) {
    for (let name of names) {
      if (isVisible) {
        sceneToolsBJS.show(name, matchNameExactly)
      } else {
        sceneToolsBJS.hide(name, matchNameExactly)
      }
    }
  }

  if (_isSketchfab()) {
    if (names.length > 0) {
      sceneTools.setObjectVisibility(names, isVisible, matchNameExactly)
    }
  }
}

const SetVisibilityGroup = function (group, option, matchNameExactly) {
  if (matchNameExactly === undefined) {
    matchNameExactly = false
  }
  // split the show and hide objects from each other. Make sure to have each object
  // in only one of the arrays to reduce object flickering
  let allObjects = []
  group.options.forEach(option => {
    allObjects = allObjects.concat(option.objects)
  });
  let hideObjects = allObjects.filter(name => !option.objects.includes(name))

  // Set the object visibility
  sceneTools.setObjectVisibility(hideObjects, false, matchNameExactly)
  sceneTools.setObjectVisibility(option.objects, true, matchNameExactly)
}

async function _SetSingleMaterialOption (sceneMaterialNames, materialPayload, liquidPayload) {
  for (let scenematerialName of sceneMaterialNames) {
    let sceneMaterial = materialTools.getSceneMaterial(scenematerialName)
    if (sceneMaterial) {
      if (materialPayload !== undefined) {
        await materialTools.setMaterialChannelsOptions(sceneMaterial, materialPayload, liquidPayload)
        if (materialPayload.cullFace) sceneMaterial.cullFace = materialPayload.cullFace
      }
      await materialTools.setMaterial(sceneMaterial)
    }
  }
}

const _setSingleMaterialOptionBJS = function (objectNames, materialId) {
  const bjsMtl = materialToolsBJS.getMtl(materialId)
  materialToolsBJS.applyMaterialByNames(objectNames, bjsMtl)
}

 const SetMaterialOption = async function (material, option, liquidPayload) {
  if (_isBabylon()) {
    if (material.type === null || material.type === 'single') {
      _setSingleMaterialOptionBJS(material.object, option.id)
    }
  }
  
  
  if (_isSketchfab()) {
    if (material.type === null || material.type === 'single') {
      await _SetSingleMaterialOption(material.scenematerial, option.payload, liquidPayload)
    }

    if (material.type === 'swappable') {
      await _SetSingleMaterialOption(material.scenematerial, option.payload, liquidPayload)
    }

    if (material.type === 'double') {
      await _SetSingleMaterialOption(material.scenematerial, option.payload, liquidPayload)
      const optionIndex = material.options.findIndex(item => item.name === option.name)
      if (optionIndex >= 0) {
        await _SetSingleMaterialOption(material.scenematerial2, material.options2[optionIndex].payload, liquidPayload)
      }else{
        await _SetSingleMaterialOption(material.scenematerial2, option.payload, liquidPayload)

      }
    }

    if (material.type === 'colorway') {
      for (let mtOption of option.payload) {
        await _SetSingleMaterialOption(mtOption.scenematerial, mtOption.payload, liquidPayload)
      }
    }

    if (material.type === 'overrideexclude') {
      const materialList = materialTools.getMaterialList()
      let arrMaterialName = []
      for (let mtl of materialList) {
        if (material.scenematerial.indexOf(mtl.name) < 0) {
          arrMaterialName.push(mtl.name)
        }
      }
      await _SetSingleMaterialOption(arrMaterialName, option.payload, liquidPayload)
    }
  }
}

const translate = function (transform, option) {
  if (_isSketchfab()) {
    if (transform.type === 'single') {
      transformTools.translate(transform.sceneobjects, option.payload.translate)
    }
  }
}

const matchMatrix = function (transform, option) {
  if (_isSketchfab()) {
    if (transform.type === 'matchmatrix') {
      option.payload.sets.forEach(set => {
        transformTools.matchMatrix(set.target, set.subjects)
      });
    }
  }
}

const SetPostProcessing = function (payload) {
  if (payload === undefined) {
    payload = store.getters['postprocessing/getPayload']
  }

  if (payload !== undefined) {
    if (_isBabylon()) {
      postprocessingToolsBJS.setupPostProcess(payload)
    }
    if (_isSketchfab()) {
      postprocessingToolsSFB.setPostProcessing(payload)
    }

  }
}

const SetBackground = function (payload) {
  if (payload === undefined) {
    payload = store.getters['background/getPayload']
  }
  
  if (payload !== undefined) {
    environmentToolsSFB.setBackground(payload)
  }
}

const SetEnvironment = function (payload) {
  if (payload === undefined) {
    payload = store.getters['environment/getPayload']
  }

  if (payload !== undefined) {
    if (_isBabylon()) {
      environmentToolsBJS.makeEnvHdr(payload.environmentmap)
      // environmentToolsBJS.buildSkybox()
      environmentToolsBJS.setupEnvironment(payload)
    }
    if (_isSketchfab()) {
      // console.log("payload", payload);
      environmentToolsSFB.setEnvironment(payload, true)
    }
  }
}

const SetStudioGroup = function (payload) {
  if (payload === undefined) {
    payload = store.getters['studiogroup/getPayload']
  }

  environmentToolsSFB.setEnvironment(payload.environment.payload, true)

      if (payload.lighting?.payload.enable !== undefined) lightingToolsSFB.setLightFeatureEnabled(payload.lighting.payload.enable)
      // if (payload.lighting === false) lightingToolsSFB.setLightFeatureEnabled(false)
      if (payload.background) environmentToolsSFB.setBackground(payload.background.payload)
      if (payload.post) postprocessingToolsSFB.setPostProcessing(payload.post.payload)

}

const SetLighting = function (payload) {
  if (payload === undefined) {
    payload = store.getters['lighting/getPayload']
  }
  if (payload !== undefined) {
    if (_isBabylon()) {
      lightingToolsBJS.setupLighting(payload.lights)
      lightingToolsBJS.setupShadows(payload.shadowgenerator)
      lightingToolsBJS.addShadowCaster()
    }
    if (_isSketchfab()) {
      if (payload.lights) {
        for (let n = 0; n < payload.lights.length; n++) {
          const element = payload.lights[n];
          lightingToolsSFB.setLight(n, element)
        }
      }
      if (payload.enable !== undefined) {
        lightingToolsSFB.setLightFeatureEnabled(payload.enable)
      }
    }
  }
}

const SetTextOption = function (theOption) {
  if (theOption != null){
    console.log('theOption', theOption)
    let bjsMtl = materialToolsBJS.getSceneMtl(theOption.scenematerial)
    console.log('bjsMtl', bjsMtl)
    if (bjsMtl != null && bjsMtl != undefined){			
      textToolsBJS.SetText(theOption.text, bjsMtl, theOption.payload)
    }	
  }
}

const setView = function (viewItem) {
  if (_isBabylon()) {
    cameraToolsBJS.editActiveCamera(viewItem)
  }
  if (_isSketchfab()) {
    cameraTools.setCameraLookAt(viewItem.eye, viewItem.lookat, viewItem.duration)
    cameraTools.setFov(viewItem.fov)
  }
}



const _printView = function () {
  if (_isBabylon()) {

  }

  if (_isSketchfab()) {
    const promiseCamera = cameraTools.getCamera()
    const promiseFov = cameraTools.getFov()

    Promise.all([promiseCamera, promiseFov]).then(values => {
      const camera = values[0]
      const angle = values[1]
      const eye = [camera.position[0].toFixed(2), camera.position[1].toFixed(2), camera.position[2].toFixed(2)]
      const lookat = [camera.target[0].toFixed(2), camera.target[1].toFixed(2), camera.target[2].toFixed(2)]
      console.log(`eye: [${eye}],
lookat: [${lookat}],
fov: ${angle}`)
    })
  }
}

const _printMaterialList = async function () {
  await materialTools.storeMaterialList()
  const materials = materialTools.getMaterialList()
  const arrCommonChannel = [
    'AOPBR',
    'Anisotropy',
    'BumpMap',
    'CavityPBR',
    'ClearCoat',
    'ClearCoatNormalMap',
    'ClearCoatRoughness',
    'Displacement',
    'EmitColor',
    'GlossinessPBR',
    'NormalMap',
    'Opacity',
    'RoughnessPBR',
    // 'SpecularColor',
    // 'SpecularHardness',
    'SubsurfaceScattering',
    'SubsurfaceTranslucency'
  ]
  const arrMetalnessAlbedoChannel = [
    'AlbedoPBR',
    'MetalnessPBR',
    'SpecularF0'

  ]
  const arrSpecularDiffuseChannel = [
    'DiffusePBR',
    'SpecularPBR'
  ]
  const arrClassicChannel = [
    'Anisotropy',
    'BumpMap',
    'ClearCoat',
    'ClearCoatNormalMap',
    'ClearCoatRoughness',
    'DiffuseColor',
    'DiffuseIntensity',
    'Displacement',
    'EmitColor',
    'Matcap',
    'NormalMap',
    'Opacity',
    'SpecularColor',
    'SpecularF0',
    'SpecularHardness',
    'SubsurfaceScattering',
    'SubsurfaceTranslucency'
  ]
  const arrMatcapChannel = [
    'BumpMap',
    'Displacement',
    'Matcap',
    'NormalMap',
    'Opacity'
  ]
  let dataDump = {}
  materials.forEach(mtl => {
    let material = {
      name: mtl.name,
      id: mtl.id
      
    }
    arrCommonChannel.sort()
    let channelNames
    //check what type of material this is: metalness or specular
    if (mtl.channels.MetalnessPBR && mtl.channels.AlbedoPBR) {
      material.type = 'Metalness/Albedo PBR'
      channelNames = arrMetalnessAlbedoChannel.concat(arrCommonChannel)
    }else if (mtl.channels.SpecularPBR && mtl.channels.DiffusePBR) {
      material.type = 'Specular/Diffuse PBR'
      channelNames = arrSpecularDiffuseChannel.concat(arrCommonChannel)
    }else{
      console.log('This isn\'t a PBR material')
    }

    function isChannelEnabled(name) {
      let result = false
      if (mtl.channels[name].enable === true) {result = true}
      if (name === 'EmitColor' && mtl.channels[name].factor === 0) {result = false}
      if (name === 'ClearCoatNormalMap' && mtl.channels['ClearCoat'].enable === false) {result = false}
      if (name === 'ClearCoatRoughness' && mtl.channels['ClearCoat'].enable === false) {result = false}
      if (result) {material[name] = mtl.channels[name]}
    }    

    channelNames.forEach(isChannelEnabled)

    function isChannelDisabled(name) {
      let result = false
      if (mtl.channels[name].enable === false) {result = true}
      if (!result && name === 'EmitColor' && mtl.channels[name].factor === 0) {result = true}
      if (!result && name === 'ClearCoatNormalMap' && mtl.channels['ClearCoat'].enable === false) {result = true}
      if (!result && name === 'ClearCoatRoughness' && mtl.channels['ClearCoat'].enable === false) {result = true}
      return result
    }    
    material.disabledChannels = channelNames.filter(isChannelDisabled)
    dataDump[mtl.name] = material
  });
  console.log(JSON.stringify(dataDump, null, 2))
}

const _printSceneGraph = function () {
  if (_isBabylon()) {
    const sc = sceneToolsBJS.getScene()
    sc.meshes.forEach(mesh => {
      console.log(mesh.name)
    });
  }
  if (_isSketchfab()) {
    const sceneGraph = sceneTools.getSceneGraph()
    console.log(sceneGraph)
  }

}

const setPreset = async function (options) {
  // async/await is very important. Meny requests are sent at the same time in a specific order
  // a preset bundles several options together which are set at the same time
  for (let option of options) {

    if (option.visibilitysingle !== undefined) {
      store.commit('visibilitysingle/setVisibility', {optionName: option.visibilitysingle, switch: option.visible})
      let visibilityItem = store.getters['visibilitysingle/getItemByName'](option.visibilitysingle)
      SetVisibilitySingle(option.visible, visibilityItem.objects, visibilityItem.exactname)      
    }
    
    if (option.material !== undefined) {
      console.log("option.material", option.material);
      let material = store.getters['material/getItemByName'](option.material)
      let materialoption = material.options.find(item => item.name === option.visible)
      store.dispatch('material/setCurrentMaterial', {material: material, option: materialoption})
      await SetMaterialOption(material, materialoption)
    }
    
    if (option.visibilitygroup !== undefined) {
      const visibilityGroupItem = store.getters['visibilitygroup/getItemByName'](option.visibilitygroup)
      if (!visibilityGroupItem) {
        console.log('Visibilitygroup not found:', option.visibilitygroup)
      } else {
        const visibilityOption = visibilityGroupItem.options.find(item => item.name === option.visible)
        store.dispatch('visibilitygroup/updateSelectedGroup', {group: visibilityGroupItem, option: visibilityOption})
        SetVisibilityGroup(visibilityGroupItem, visibilityOption, visibilityOption.matchNameExactly)
      }
    }
    
    if (option.transform !== undefined) {
      const transform = store.getters['transform/getItemByName'](option.transform)
      const transformOption = transform.options.find(item => item.name === option.default)
      store.dispatch('transform/setCurrentTransform', {transform: transform, option: transformOption})
      if (transform.type === 'translate') translate(transform, transformOption)
      if (transform.type === 'matchmatrix') matchMatrix(transform, transformOption)
    }

    if (option.environment !== undefined) {
      let environment = store.getters['environment/getTemplateByName'](option.environment)
      SetEnvironment(environment.payload)
    }

    if (option.postprocessing !== undefined) {
      let postprocessing = store.getters['postprocessing/getTemplateByName'](option.postprocessing)
      SetPostProcessing(postprocessing.payload)
    }

    if (option.background !== undefined) {
      let background = store.getters['background/getTemplateByName'](option.background)
      SetBackground(background.payload)
    }

    if (option.lighting !== undefined) {
      let lighting = store.getters['lighting/getTemplateByName'](option.lighting)
      SetLighting(lighting.payload)
    }
    
    if (option.presetgroup !== undefined) {
      let presetgroup = store.getters['presetgroup/getItemByName'](option.presetgroup)
      let presetgroupoption = presetgroup.options.find(item => item.name === option.visible)
      store.dispatch('presetgroup/updateSelectedGroup', {group: presetgroup, option: presetgroupoption})
      await SetPresetGroup(presetgroup, presetgroupoption)      
    }
        
    if (option.optionhook !== undefined) {
      let optionhook = store.getters['optionhook/getItemByName'](option.optionhook)
      let optionhookoption = optionhook.options.find(item => item.name === option.current)
      store.dispatch('optionhook/updateSelectedOptionhook', {group: optionhook, option: optionhookoption})
      
      // REDO ALL PRESETGROUPS WHICH USE THIS HOOK
      let presetgroups = store.state.presetgroup.all
      for (let presetgroup of presetgroups) {
        const hookedOption = store.getters['presetgroup/getOptionFromGroupByHook'](presetgroup.name, presetgroup.visible, optionhook.name, optionhookoption.name)
        
        if (hookedOption) {
          await SetPresetGroup(presetgroup, hookedOption)
        }
      }
    }

  }
}

const SetPresetGroup = async function (presetgroup, option) {
  let theOption = filterPresetgroupoptionByHook(presetgroup, option)
  await setPreset(theOption.options)
}

const filterPresetgroupoptionByHook = function (group, option) {
  const options = group.options.filter(item => item.name === option.name)
  if (options.length > 1) {
    if (option.optionhook) {
      // there are multiple options in the group and there's a hook. 
      let optionhook = store.getters['optionhook/getItemByName'](option.optionhook.name)
      let optionhookoption = optionhook.options.find(item => item.name === optionhook.current)
      const hookedOption = store.getters['presetgroup/getOptionFromGroupByHook'](group.name, option.name, optionhook.name, optionhookoption.name)
      if (hookedOption) {
        return hookedOption
      } else {
        return option
      }
    } else {
      return option
    }
  } else {
    return option
  }
}

const getScreenshot = function (width, height, callback) {
  screenshot.getScreenShot(width, height, callback)
}

const loadScene = function (sceneuid) {
  store.dispatch('scenes/setSceneLoading')
  const playerSettings = store.getters['player/getPayload']
  playerTools.loadScene(
    sceneuid, 
    playerSettings, 
    "player__iframe", 
    playerTools.playerSuccess
  )
}

const parseProjectData = function (scene) {
  console.log('FETCHING DATA')
  store.dispatch('labels/fetchData', {scene: scene})
  store.dispatch('player/fetchData', {scene: scene})
  store.dispatch('background/fetchData', {scene: scene})
  store.dispatch('environment/fetchData', {scene: scene})
  store.dispatch('postprocessing/fetchData', {scene: scene})
  store.dispatch('lighting/fetchData', {scene: scene})
  store.dispatch('annotations/fetchData', {scene: scene})

  store.dispatch('menu/fetchData', {menu: scene.menu})
  store.dispatch('materialswappable/fetchData', {menu: scene.menu})
  store.dispatch('material/fetchData', {menu: scene.menu})
  store.dispatch('studiogroup/fetchData', {menu: scene.menu})
  store.dispatch('views/fetchData', {menu: scene.menu})
  store.dispatch('presets/fetchData')
  store.dispatch('animations/fetchData')
  store.dispatch('sensar/fetchData')
  store.dispatch('nmalight/fetchData')
  store.dispatch('text/fetchData')
  store.dispatch('visibilitysingle/fetchData', {menu: scene.menu})
  store.dispatch('visibilitygroup/fetchData', {menu: scene.menu})
  store.dispatch('presetgroup/fetchData', {menu: scene.menu})
  store.dispatch('optionhook/fetchData', {menu: scene.menu})
  store.dispatch('transform/fetchData', {menu: scene.menu})

  store.dispatch('liquid/fetchData', {menu: scene.menu})
  console.log('FETCHED DATA')
}

const makeSummaryScreenshot = function (cameraName) {
  let cam = store.getters['views/getItemByName'](cameraName)
  cam.payload.duration = 0.1
  setView(cam.payload)
  setTimeout(() => {
    screenshot.getScreenshotPromise(1024,1024)
    .then(result => {
      store.commit('ui/updateItemByName', {key: 'screenshot', value: `data:image/png;base64,${result}`})
    }) 
  }, 250);

}

window.configuratorapi.SetSingleMaterialOption = _SetSingleMaterialOption
window.configuratorapi.printView = _printView
window.configuratorapi.printSceneGraph = _printSceneGraph
window.configuratorapi.printMaterialList = _printMaterialList
window.configuratorapi.getScreenshot = getScreenshot

export default {
  SetMaterialOption,
  SetVisibilitySingle,
  SetVisibilityGroup,
  setView,
  translate,
  matchMatrix,
  setPreset,
  SetPresetGroup,
  getScreenshot,
  SetPostProcessing,
  SetTextOption,
  SetEnvironment,
  SetLighting,
  SetBackground,
  SetStudioGroup,
  loadScene,
  parseProjectData,
  makeSummaryScreenshot
}
