<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'visibilitygroupoption',
  components: {
  },
  props: {
    visibilitygroup: Object,
    option: Object
  },
  data: () => ({
  }),
  computed: {
    isCurrent () {
      return this.visibilitygroup.visible === this.option.name
    }
  },
  methods: {
    setVisibilityOption (visibilitygroup, option) {
      this.$store.dispatch('visibilitygroup/updateSelectedGroup', {group: visibilitygroup, option: option})
      configure.SetVisibilityGroup(visibilitygroup, option, option.matchNameExactly)
    }
  }  
}
</script>

<template>

  <v-list-item 
    @click="setVisibilityOption(visibilitygroup, option)"
  >
    <v-list-item-title 
      v-text="option.title"
    ></v-list-item-title>

    <v-avatar 
      v-if="option.swatch" 
      :style="option.swatch" 
      tile
    >
    </v-avatar> 

    <v-list-item-icon class="my-2" v-if="isCurrent">
      <v-icon color="accent">mdi-check-circle</v-icon>
    </v-list-item-icon>    

    <v-list-item-icon class="my-2" v-else>
      <v-icon color="cardaccent">mdi-circle-outline</v-icon>
    </v-list-item-icon>              
  </v-list-item>
</template>
