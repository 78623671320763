import scene from './scene'


const SetText = function (theText, sceneMaterial, payload) {
    console.log('theText', theText)
    console.log('sceneMaterial', sceneMaterial)
    console.log('payload', payload)
    if (payload != null && payload != undefined){
        for (let [channelName, value] of Object.entries(payload.channels)) {
            if (sceneMaterial[channelName] != null) {
                let dynamicTexture = sceneMaterial[channelName]
                let ctx = dynamicTexture.getContext();
                ctx.clearRect(0,0,512,512);
                dynamicTexture.update(false);
                let textWidth = ctx.measureText(theText).width;
                let xpos = 256 - textWidth/2
                dynamicTexture.drawText(theText, xpos, 350, payload.font, payload.textColor, payload.canvasColor, true, true);
            }
        }
    }
    let sc = scene.getScene()
    sc.render();
}

export default { SetText }
