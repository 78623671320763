import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: colors.pink.lighten1, // #E53935
        secondary: '#81D4FA', //colors.lightblue.lighten3
        accent: colors.lightBlue.darken4, //colors.lightblue.darken4
        card: colors.grey.lighten3, // #3F51B5
        cardaccent: colors.grey.lighten2,
        cardaccent2: colors.shades.white,
        cardcontrast: colors.grey.darken3,
        textcontrast: colors.grey.darken4

      },
      dark: {
        primary: '#10AAD5', //colors.pink.darken2, // #E53935
        secondary: colors.green.darken3, //colors.lightblue.lighten3
        // accent: '#64FFDA',
        accent: colors.lightBlue.darken3, //colors.lightblue.darken4

        card: '#313131',
        cardaccent: colors.grey.darken3,
        cardaccent2: colors.grey.darken4,
        cardcontrast: colors.grey.lighten2,

        textcontrast: colors.grey.lighten4
      },
    },
  },  
});
