import { render, staticRenderFns } from "./liquid-preset-menu.vue?vue&type=template&id=c6aab424&scoped=true&"
import script from "./liquid-preset-menu.vue?vue&type=script&lang=js&"
export * from "./liquid-preset-menu.vue?vue&type=script&lang=js&"
import style0 from "./liquid-preset-menu.vue?vue&type=style&index=0&id=c6aab424&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6aab424",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAvatar,VSheet})
