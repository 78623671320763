import _base from './_base-module'
import templateData from 'ProjectData/template'

const getOption = function (option) {
  if (option.presetgroup) {
    return templateData.presetgroup.find(item => item.name === option.presetgroup)
  }
  return null
}

const state = {
  all: []
}

const getters = {
  ..._base.getters,
  getOptionFromGroupByHook: (state) => (presetgroupname, optionname, hookname, hookoptionname) => {
    const presetgroup = state.all.find(item => item.name === presetgroupname)
    if (presetgroup) {
      let presetgroupoptions = presetgroup.options.filter(o => o.name === optionname)
      if (presetgroupoptions.length > 0) {
        // there are multiple options for the optionname. Verify they're linked
        // by the same optionhook
        if (presetgroupoptions.every(item => item.optionhook?.name === hookname)) {
          const hookedOption = presetgroupoptions.find(item => item.optionhook.option === hookoptionname)
          if (hookedOption) {
            return hookedOption
          }
        }
      }
    }
    return null
  },
}

const actions = {
  updateSelectedGroup ({ commit }, {group, option}) {
    commit('updateSelectedGroup', {group: group, option: option})
  },    
  fetchData ({ commit }, {menu}) {
    commit('clear')
    let options = []
    for (let menuitem of menu) {
      if (menuitem.options) {
        for (let option of menuitem.options) {
          options.push(option)
        }
      }
    }
    for (let menuOption of options) {
      const templateOption = getOption(menuOption)
      if (templateOption !== null) {
        if (templateOption.visible === undefined) {
          templateOption.visible = templateOption.options[0].name
        }
        templateOption.init = true
        if (menuOption.init !== undefined) templateOption.init = menuOption.init

        let currentoption = templateOption.options.find(item => item.name === templateOption.visible)
        commit('addOption', templateOption)
        commit('updateSelectedGroup', {group: templateOption, option: currentoption})
      }
    }
  }
}

const mutations = {
  ..._base.mutations,
  addOption (state, theOption) {
    if (theOption !== null) {
      state.all.push(theOption)
    }
  },
  clear (state) {
    state.all = []
  },
  updateSelectedGroup (state, payload) {
    state.all = state.all.map(item => {
      if (item.name === payload.group.name) {
        item.currentTitle = payload.option.title
        item.swatch = payload.option.swatch
        item.icon = payload.option.icon
        item.visible = payload.option.name
      } return item
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
