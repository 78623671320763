<script>
import materialmenu from './material-menu'

export default {
  name: 'materialoption',
  components: {
    materialmenu
  },
  props: {
    optionname: String
  },
  computed: {
    material () {
      return this.$store.getters['material/getItemByName'](this.optionname)
    },
    icon () {
      if (this.material.icon) {
        return (process.env.BASE_URL + this.material.icon)
      } else {
        return null
      }
    },    
  },
  methods: {
  }  
}
</script>

<template>
  <v-list-group
    v-if="material && material.options.length > 1"
    color='primary'
  >
    <template v-slot:activator>
      <v-list-item-avatar 
        v-if="material.swatch" 
        :color="material.swatch" 
        class="elevation-4"
        left
      />
      <v-list-item-avatar v-if="icon" class="elevation-4">
        <v-img :src="icon"/>
      </v-list-item-avatar>      

      <v-list-item-content color='cardaccent' two-line>
          <v-list-item-title v-text="material.title"></v-list-item-title>
          <v-list-item-subtitle>{{ material.currentTitle }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
      <v-divider></v-divider>

      <materialmenu :options="material.options" :name="material.name"/>

  </v-list-group>
</template>