import _base from './_base-module'
import templateData from 'ProjectData/template'

const state = {
  all: []
}

const getters = {
  ..._base.getters,
}

const actions = {
  fetchData ({ commit }, {menu}) {
    commit('clearAll')
    for (let view of templateData.views) {
      commit('addView', view)
    }
  }
}

const mutations = {
  ..._base.mutations,
  addView (state, view) {
    state.all.push(view)
  },
  clearAll (state) {
    state.all = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
