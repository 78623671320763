const state = {
  all: {}
}

const getters = {
  getMaterial: (state) => (name) => {
    return state.all[name]
  }
}

const actions = {
  storeMaterial ({ commit }, material) {
    commit('storeMaterial', material)
  },
}

const mutations = {
  storeMaterial(state, material) {
    state.all[material.name] = material
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
