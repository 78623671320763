<script>
import ModelList from "../model-list.vue"

export default {
  name: 'desktopmodelpicker',
  components: {
    ModelList
  },
  computed: {
    drawer: {
      get: function () { return this.$store.state.ui.desktopmodelpicker },
      set: function (newValue) { this.$store.commit('ui/setDesktopModelpicker', newValue) }
    },      
  },
}
</script>

<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    temporary
    width="400px"
  >
    <ModelList/>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
</style>