import Vue from 'vue'
import Vuex from 'vuex'

import animations from './modules/animations'
import scenes from './modules/scenes'
import menu from './modules/menu'
import material from './modules/material'
import materialswappable from './modules/materialswappable'
import player from './modules/player'
import background from './modules/background'
import lighting from './modules/lighting'
import liquid from './modules/liquid'
import studiogroup from './modules/studiogroup'
import environment from './modules/environment'
import postprocessing from './modules/postprocessing'
import presets from './modules/presets'
import presetgroup from './modules/presetgroup'
import sensar from './modules/sensar'
import nmalight from './modules/nmalight'
import views from './modules/views'
import visibilitysingle from './modules/visibilitysingle'
import visibilitygroup from './modules/visibilitygroup'
import ui from './modules/ui'
import annotations from './modules/annotations'
import labels from './modules/labels'
import transform from './modules/transform'
import text from './modules/text'
import optionhook from './modules/optionhook'
import sf_textures from './modules/sf_textures'
import sf_materials from './modules/sf_materials'

Vue.use(Vuex)

const getters = {}
const state = {}
const actions = {}
const mutations = {}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    animations,
    annotations,
    background,
    environment,
    lighting,
    studiogroup,
    labels,
    liquid,
    material,
    materialswappable,
    menu,
    player,
    postprocessing,
    presets,
    presetgroup,
    scenes,
    sensar,
    nmalight,
    ui,
    views,
    visibilitygroup,
    visibilitysingle,
    transform,
    text,
    optionhook,
    sf_textures,
    sf_materials
  },
  strict: true
})
