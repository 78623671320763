class Sensar {
    constructor(animation) {
        this.name = ''
        this.title = 'Unknown sensar'
        this.startframe = 0
        this.endframe = 99
        this.playbackspeed = 50
        this.currentframe = 0
        this.deformationurl = ''
        this.deformation = []
        this.deformationmultiplier = 100

        if (animation) {
            this.name = animation.name ? animation.name : ''
            this.title = animation.title ? animation.title : ''
            this.startframe = animation.payload.startframe ? animation.payload.startframe : 0
            this.endframe = animation.payload.endframe ? animation.payload.endframe : 99
            this.playbackspeed = animation.payload.playbackspeed ? animation.payload.playbackspeed : 50
            this.currentframe = animation.payload.currentframe ? animation.payload.currentframe : 0
            this.deformationurl = animation.payload.deformationurl ? animation.payload.deformationurl : ''
            this.deformation = animation.payload.deformation ? animation.payload.deformation : []
            this.deformationmultiplier = animation.payload.deformationmultiplier ? animation.payload.deformationmultiplier : 100
        }
    }
}

export default Sensar