<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'presetoption',
  components: {
  },
  props: {
    optionname: String
  },
  computed: {
    preset () {
      return this.$store.getters['presets/getItemByName'](this.optionname)
    }
  },
  methods: {
    setPreset () {
      configure.setPreset(this.preset.options)
    }
  }  
}
</script>

<template>
    <v-list-item v-if="preset !== undefined" @click="setPreset">
      <v-list-item-content two-line>
        <v-list-item-title v-text="preset.title"></v-list-item-title>
        <v-list-item-subtitle>Preset</v-list-item-subtitle>
      </v-list-item-content>
      <v-icon >mdi-menu-right</v-icon>
    </v-list-item>
</template>