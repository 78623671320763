import _base from './_base-module'
import templateData from 'ProjectData/template'
import Sensar from '@/classes/Sensar'
const Model = Sensar

const state = {
    all: [],
    interval: null
}

const getters = {
    ..._base.getters 
}

const actions = {
    fetchData ({ commit }) {
        commit('clearAll')
        templateData.sensar && templateData.sensar.forEach(item => {
            commit('addItem', item)
        })
    },
    setInterval ({ commit }, {interval}) {
        commit('setInterval', interval)
    },
    clearInterval ({ commit }) {
        commit('clearInterval')
    },
    setDeformationUid ({ commit }, {sensarname, deformationObject, uid}) {
        commit('setDeformationUid', {sensarname: sensarname, deformationObject: deformationObject, uid: uid})
    }
}

const mutations = {
    ..._base.mutations,
    addItem (state, item) {
        item.payload.endframe = item.payload.deformation.length-1
        let sensar = new Model(item)
        state.all.push(sensar)
    },
    clearAll (state) {
       state.all = []
    },
    setInterval (state, interval) {
        state.interval = interval
    },
    clearInterval (state) {
        clearInterval(state.interval)
        state.interval = null
    },
    setDeformationUid (state, payload) {
        const newstate = state.all.map(sensarobject => {
            if (sensarobject.name === payload.sensarname) {
                const newdeformation = sensarobject.deformation.map(def => {
                    if (def.image === payload.deformationObject.image) {
                        def.uid = payload.uid
                        return def
                    } else {
                        return def
                    }
                })
                sensarobject.deformation = newdeformation
                return sensarobject
            } else {
                return sensarobject
            }
        })
        state.all = newstate
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
