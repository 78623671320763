import _base from './_base-module'
import templateData from 'ProjectData/template'
import libraryData from 'ProjectData/library'

const state = {
  library: [],
  template: [],
  payload: {}
}

const getters = {
  ..._base.getters
}

const actions = {
  fetchData ({ commit }, {scene}) {
    commit('setLibrary', libraryData.player)
    commit('setTemplate', templateData.player)
    commit('setLibraryTemplatePayload', scene.player)
  }
}

const mutations = {
  ..._base.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
