import store from '../../store'
import player from './player'

const storeTextureList = function () {
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.getTextureList(function (err, theTextureList) {
        if (err) {
          console.log(err.stack)
        } else {
          theTextureList.forEach(texture => {
            store.dispatch('sf_textures/storeTexture', texture)
          });
          resolve()
        }
      })
    }
  })
}

const getTextureUidByName = function (textureName) {
  const texture = store.getters["sf_textures/getTextureByName"](textureName)
  return texture?.uid
}

const addTexture = function (textureUrl) {
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.addTexture(textureUrl, async function (err, uid) {
        if (err) {
          console.log(err)
        } else {
          await storeTextureList()
          resolve(uid)
        }
      })
    }
  })
}

const updateTexture = function (textureUrl, textureUid) {
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.updateTexture(textureUrl, textureUid, function (err, uid) {
        if (err) {
          console.log(err.stack)
        } else {
          resolve(uid)
        }
      })
    }
  })
}

const makeTexture = function (uid, uv) {
  const texture = {
    magFilter: 'LINEAR',
    minFilter: 'LINEAR_MIPMAP_LINEAR',
    wrapS: 'REPEAT',
    wrapT: 'REPEAT',
    textureTarget: 'TEXTURE_2D',
    internalFormat: 'RGB',
    texCoordUnit: uv,
    uid: uid
  }
  return texture
}

window.configuratorapi.getTextureUidByName = getTextureUidByName


export default { storeTextureList, getTextureUidByName, makeTexture, addTexture, updateTexture }
