import player from './player'
import sceneTools from './scene'
import { glMatrix, vec3, mat4 } from 'gl-matrix'

const translate = function (objectNames, position) {
  let nodes = []
  objectNames.forEach(element => {
    nodes = nodes.concat(sceneTools.getNodeFromGraphByNameAndType(null, element, 'MatrixTransform', true))        
  });

  let api = player.getApi()
  if (api) {
    nodes.forEach(node => {
      api.translate(node.instanceID, position, 5000, 'easeOutQuad', function(err, translateTo) {
      })
    });
  }    
}

const matchMatrix = function (targetName, subjectNames) {
  // move a series of subjects to a target object
  let subjects = []
  subjectNames.forEach(element => {
    subjects = subjects.concat(sceneTools.getNodeFromGraphByNameAndType(null, element, 'MatrixTransform', true))        
  });
  let targets = sceneTools.getNodeFromGraphByNameAndType(null, targetName, 'MatrixTransform', true)
  if (targets[0]) {
    let api = player.getApi()
    api.getMatrix(targets[0].instanceID, function(err, matrix) {
      if (!err) {
          subjects.forEach(subject => {
            api.setMatrix(subject.instanceID, matrix.local, function(err2) {
              if (!err2) {
              } else {
                console.log('err2', err2)
              }
            })
          });
      } else (
        console.log('err', err)
      )
    });
  }
}

const getLocalMatrix = function (nodeName) {
  return new Promise((resolve, reject) => {
    let nodes = sceneTools.getNodeFromGraphByNameAndType(null, nodeName, 'MatrixTransform', true)
    if (nodes[0]) {
      let api = player.getApi()
      if (api) {
        api.getMatrix(nodes[0].instanceID, function(err, matrix) {
          const m4source = mat4.fromValues(
            matrix['local'][0],
            matrix['local'][1],
            matrix['local'][2],
            matrix['local'][3],
            matrix['local'][4],
            matrix['local'][5],
            matrix['local'][6],
            matrix['local'][7],
            matrix['local'][8],
            matrix['local'][9],
            matrix['local'][10],
            matrix['local'][11],
            matrix['local'][12],
            matrix['local'][13],
            matrix['local'][14],
            matrix['local'][15]
          )          
          resolve(m4source)
        })
      } else {
        reject(new Error('API not found'))
      }
    }
  })
}

const rotate = function (nodeName, amount, baseMatrix) {
  let nodes = sceneTools.getNodeFromGraphByNameAndType(null, nodeName, 'MatrixTransform', true)

  if (nodes[0] && baseMatrix) {
    let api = player.getApi()
    api.getMatrix(nodes[0].instanceID, function(err, matrix) {

      let m4 = mat4.create()
      mat4.rotateZ(m4, baseMatrix, glMatrix.toRadian(amount-90))

      // some nifty axis swap to get the rotation right
      matrix['local'][0] = m4[4]
      matrix['local'][1] = m4[5]
      matrix['local'][2] = m4[6]
      matrix['local'][3] = m4[7]

      matrix['local'][4] = -m4[0]
      matrix['local'][5] = -m4[1]
      matrix['local'][6] = -m4[2]
      matrix['local'][7] = -m4[3]

      matrix['local'][8] = m4[8]
      matrix['local'][9] = m4[9]
      matrix['local'][10] = m4[10]
      matrix['local'][11] = m4[11]

      matrix['local'][12] = m4[12]
      matrix['local'][13] = m4[13]
      matrix['local'][14] = m4[14]
      matrix['local'][15] = m4[15]
      
      api.setMatrix(nodes[0].instanceID, matrix['local'])
    })
  }
}

export default {
  translate,
  matchMatrix,
  getLocalMatrix,
  rotate
}
