import player from './player'

let sceneGraph

// get the scenegraph from the scene and add the parents to each node
const storeSceneGraph = function () {
  return new Promise((resolve, reject) => {
    let api = player.getApi()
    if (api) {
      api.getSceneGraph(function (err, theSceneGraph) {
        if (err) {
          console.log(err.stack)
        } else {
          _addSceneGraphFoodchain(theSceneGraph, null)
          sceneGraph = theSceneGraph
          resolve()
        }
      })
    } else {
      reject(new Error('API not found'))
    }
  })
}

const getSceneGraph = function () {
  return sceneGraph
}

const _matchesName = function (node, name, matchExactName) {
  if (node.name === undefined) {
    // console.log('name', name)
    // console.log('node', node)
    return false
  } else {
    return (matchExactName && node.name === name) || (!matchExactName && node.name.indexOf(name) > -1)
  }
}

const getNodeFromGraphByNameAndType = function (node, name, type, matchExactName) {
  let result = []
  if (!node) {
    node = sceneGraph
  }
  if (node.type === type) {
    if (_matchesName(node, name, matchExactName)) {
      result.push(node)
    }
  }

  if (node.hasOwnProperty('children')) {
    node.children.forEach(function (child) {
      result = result.concat(getNodeFromGraphByNameAndType(child, name, type, matchExactName))
    })
  }
  return result
}

// go over the scenegraph and add the parent to each node
const _addSceneGraphFoodchain = function (currentNode, parentNode) {
  if (parentNode !== null) {
    currentNode.parent = parentNode
  }

  if (currentNode.hasOwnProperty('children')) {
    currentNode.children.some(child => _addSceneGraphFoodchain(child, currentNode))
  }
}

// get a node from the graph with a specific instanceID
const _getNodeFromGraphByID = function (currentNode, instanceID) {
  if (currentNode.instanceID === instanceID) {
    return currentNode
  }
  if (currentNode.hasOwnProperty('children')) {
    var node
    currentNode.children.some(child => { node = _getNodeFromGraphByID(child, instanceID) })
    return node
  }
}

// after a click event, sketchfab returns a modified objectname. this method
// iterates over the parent objects and tries to get the name from them
const getClickedNode = function (instanceID, callback) {
  var clickedNode = _getNodeFromGraphByID(sceneGraph, instanceID)
  if (clickedNode !== undefined && clickedNode.parent.type === 'Group') {
    return clickedNode.parent
  }
  return null
}

const show = async function (namePattern, matchNameExactly) {
  let api = player.getApi()

  if (api) {
    await _setObjectVisibility(namePattern, true, matchNameExactly)
  }
}

const hide = async function (namePattern, matchNameExactly) {
  let api = player.getApi()

  if (api) {
    await _setObjectVisibility(namePattern, false, matchNameExactly)
  }
}

const _setObjectVisibility = function (namePattern, doShow, matchNameExactly) {
  console.log("namePattern", namePattern);
  return new Promise((resolve, reject) => {
    let api, matchedNodeArray, patternArray
    api = player.getApi()

    if (api) {
      matchedNodeArray = []
      // the objectnames may be supplied as a comma separated string
      patternArray = namePattern.split(',')

      // first find the nodes
      for (let name of patternArray) {
        let matches = getNodeFromGraphByNameAndType(null, name, 'MatrixTransform', matchNameExactly)
        matchedNodeArray = matchedNodeArray.concat(matches)
      }
      
      if (matchedNodeArray.length === 0) resolve()

      for (let node of matchedNodeArray) {
        if (doShow) {
          api.show(node.instanceID, function () {
            resolve()
          })
        } else {
          api.hide(node.instanceID, function () {
            resolve()
          })
        }
      }
    }else{
      reject()
    }
  })
}

const setObjectVisibility = function (objectNames, doShow, matchNameExactly) {
  let api, matchedNodeArray
  api = player.getApi()

  if (api) {
    matchedNodeArray = []

    // first find the nodes
    for (let name of objectNames) {
      let matches = getNodeFromGraphByNameAndType(null, name, 'MatrixTransform', matchNameExactly)
      matchedNodeArray = matchedNodeArray.concat(matches)
    }

    for (let node of matchedNodeArray) {
      if (doShow) {
        api.show(node.instanceID)
      } else {
        api.hide(node.instanceID)
      }
    }
  }
}

export default { 
  storeSceneGraph,
  getSceneGraph,
  getNodeFromGraphByNameAndType,
  getClickedNode,
  show,
  hide,
  setObjectVisibility
}
