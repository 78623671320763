<script>
import configure from '@/tools/configurator/configure'
import annotationTools from '@/tools/sketchfab/annotations'
import materialmenu from './material-menu'
import visibilitysingleoption from './visibilitysingle-option'
import visibilitygroupmenu from './visibilitygroup-menu'
import labeloption from './label-option'

export default {
  name: 'annotationoverlayitem',
  components: {
    materialmenu,
    visibilitysingleoption,
    visibilitygroupmenu,
    labeloption
  },
  props: {
    annotation: Object,
    show: Boolean,
    cameraMoves: Boolean,
    juststartedmoving: Boolean,
    juststoppedmoving: Boolean,
    action: Object
  },
  watch: {
    visible: {
      immediate: true,
      handler: function (val, oldVal) {
        if (!oldVal && val) {
          this.setAnnotationPos(this)
          this.setAnnotationOcclusion(this)
        }
      }
    }
  },
  data: function () {
    return {
      position2d: [0, 0],
      occluded: false,
      pendingoccluded: true,
      clicked: false,
    }
  },  
  computed: {
    myStyle () {
      // when moving, the annotation is transparent and non-clickable
      // when static, the annotation is clickable and opaque if no geometry
      // occludes it. When it is occluded, it's still clickable, but not expandable
      let opa = 1.0
      let pointer = 'all'
      if (this.cameraMoves) {
        pointer = 'none' //annotations are non-clickable while moving the camera
        opa = this.ui.movingannotationopacity
        
      } else {
        if (this.pendingoccluded) {
            opa = this.ui.movingannotationopacity
        } else {
            opa = this.occluded ? this.ui.occludedannotationopacity : 1.0
        }
      }
      //always show the clicked annotation
      if (this.clicked) opa = 1.0
      const z = this.show ? 10 : 2
      return {
        transform: 'translate(' + (this.position2d[0]) + 'px,' + (this.position2d[1]) + 'px)',
        opacity: opa,
        'pointer-events': pointer,
        'z-index': z
      }
    },
    ui () {
      return this.$store.getters['ui/getState']
    },    
    visible () {
      return this.$store.getters['annotations/getShow']
    },
    floatingtitle () {
      if (this.show) return this.annotation.title; else return this.annotation.title

    }
  },
  mounted: function () {
    this.tick()

  },
  methods: {
    tick () {
      // This tick is called every frame. If the camera is moving, we update the position
      // of the annotation. If the camera has just stopped, we update the occlusion
      // of the annotation but we don't update the position anymore.
      const self = this
      if (self.cameraMoves) {
        self.occluded = true
        self.pendingoccluded = true
        // self.juststoppedmoving = true
        self.setAnnotationPos(self)
      } else {
        if (self.juststoppedmoving) {
          // Doing 3d raycasting is too heavy on most models. The annotations will lag and stutter
          // when done every frame. Only raycast once the camera stops moving
          self.setAnnotationOcclusion(self)
        }
        // self.juststoppedmoving = false
      }
      requestAnimationFrame(self.tick)
    },
    setAnnotationPos (self) {
      annotationTools.updateAnnotation(self.annotation.payload.position3d).then(
        function (result) {
          self.position2d = [result[0], result[1]]
        }
      )
    },
    setAnnotationOcclusion (self) {
      annotationTools.getAnnotationOcclusion(self.annotation.payload.position3d).then(
        function(result) {
          self.occluded = result != undefined
          self.pendingoccluded = false
          self.clicked = false
        }
      )
    },
    expandAnnotation () {
      // show the card
      this.occluded = false
      this.$store.commit('annotations/setVisible', {annotationName: this.annotation.name, visibility: !this.show})
    },
    activateView () {
      this.$store.commit('annotations/updateCurrent', {name: this.annotation.name})
      this.clicked = true
      if (this.annotation.payload.view) {
        const view = this.$store.getters['views/getItemByName'](this.annotation.payload.view)
        configure.setView(view.payload)
      }
      if (this.ui.expandactiveannotation) {
        this.expandAnnotation()
      }
    }
  }  
}
</script>

<template>
<v-card class="annotation mx-auto" max-width="360" max-height="360" :style="myStyle">
  <v-card-actions v-if="cameraMoves || pendingoccluded" class="pa-0">
    <v-btn icon @click="activateView()">
      <v-icon>mdi-eye</v-icon>
    </v-btn>
  </v-card-actions>
  <!-- <v-card-actions v-if="!cameraMoves && occluded && !pendingoccluded" class="pa-0">
    <v-card-title class="subtitle-1 px-3 py-1" @click="activateView()">{{ floatingtitle }}</v-card-title>
  </v-card-actions> -->
  <v-card-actions v-if="!cameraMoves && !occluded && !pendingoccluded" class="pa-0">
    <v-card-title class="subtitle-1 px-3 py-1" @click="activateView()">{{ floatingtitle }}</v-card-title>
    <v-spacer></v-spacer>
    <v-btn icon @click="expandAnnotation()">
      <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
    </v-btn>
  </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <materialmenu v-if="action && action.material" :options="action.material.options" :name="action.material.name"/>
        <visibilitysingleoption v-if="action && action.visibilitysingle" :optionname="action.visibilitysingle.name"/>
        <visibilitygroupmenu v-if="action && action.visibilitygroup" :options="action.visibilitygroup.options" :name="action.visibilitygroup.name"/>
        <labeloption v-if="action && action.label" :optionname="action.label.name"/>
      </div>
    </v-expand-transition>  
</v-card>
</template>

<style lang="scss" scoped>
.annotation {
  position: absolute;
  // pointer-events: all;
  transition-duration: 0s;
  overflow-y: auto;
  left: -24px;
  top: -24px;
  transition: opacity 0.5s ease-in-out;
  border-radius: 18px;
}
</style>
