<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'materialswappeemenu',
  props: {
    options: Array,
    name: String
  },
  computed: {
    currentMaterial () {
      return this.$store.getters['materialswappable/getItemByName'](this.name)
    }
  },
  methods: {
    setMaterial (materialswappable, option) {
      this.$store.dispatch('materialswappable/setCurrentMaterial', {material: materialswappable, option: option})

      //change the material properties of all materials which subscribe 
      //to this swappable
      const allSwp = this.$store.getters['materialswappable/getAllSwappable']
      allSwp.forEach(element => {
        if (element.visible === materialswappable.name) {
          configure.SetMaterialOption(element, option)
        }
      })

      if (materialswappable.actions) {
        if (materialswappable.actions.backgroundcolor) {
          let actionColor = option.payload[materialswappable.actions.backgroundcolor].color
          let theColor = [actionColor[0]/255, actionColor[1]/255, actionColor[2]/255, ]
          configure.SetBackground({enable: 'color', color: theColor })
        }
      }
    },    
  }  
}
</script>

<template>
<div>
  <v-sheet
    class="d-flex flex-wrap pa-2"
    elevation="0"
  >
    <v-sheet
      v-for="(option, index) in options" 
      :key="index" 
    >
      <v-avatar
        tile
        v-if="option.swatch" 
        :color="option.swatch" 
        @click="setMaterial(currentMaterial, option)"
        size=62
      >
      </v-avatar>
    </v-sheet>
  </v-sheet>
</div>
</template>