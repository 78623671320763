import store from '@/store'
import scene from '../sketchfab/scene';
import player from '../sketchfab/player';

let interval_s
let interval_m
let interval_h
let timeActive = false

const startTime = function () {
  if (store.state.scenes.currentScene.scenetitle === "Submarine Commander") {
    let hand_s = scene.getNodeFromGraphByNameAndType(null, 'hand_s', 'MatrixTransform', true)
    let hand_m = scene.getNodeFromGraphByNameAndType(null, 'hand_m', 'MatrixTransform', true)
    let hand_h = scene.getNodeFromGraphByNameAndType(null, 'hand_h', 'MatrixTransform', true)

    if (hand_s.length > 0 && hand_m.length > 0 && hand_h.length > 0) {
      timeActive = true
      // set the initial rotation of the minute and hour hands
      rotate_m(hand_m[0].instanceID, true)
      rotate_h(hand_h[0].instanceID, true)
      
      interval_s = setInterval(() => {
        rotate_s(hand_s[0].instanceID)
      }, 1000);
      interval_m = setInterval(() => {
        rotate_m(hand_m[0].instanceID, false)
      }, 1000);
      interval_h = setInterval(() => {
        rotate_h(hand_h[0].instanceID, false)
      }, 1000);

      player.api.addEventListener('click', function (info) {
        if (timeActive) {
          stopTime(hand_s[0].instanceID, hand_m[0].instanceID, hand_h[0].instanceID)
        } else {
          startTime()
        }
      }, { pick: 'fast' })
    }
  }
}

const stopTime = function (id_s, id_m, id_h) {
  clearInterval(interval_s)
  clearInterval(interval_m)
  clearInterval(interval_h)

  interval_s = null
  interval_m = null
  interval_h = null
  
  player.api.rotate(id_s, [Math.PI * 1.4, 0, 0, 1], {duration: 0.5})
  player.api.rotate(id_m, [Math.PI * 0.3, 0, 0, 1], {duration: 0.5})
  player.api.rotate(id_h, [Math.PI * 1.7, 0, 0, 1], {duration: 0.5})
  timeActive = false
}

const rotate_s = function (instanceID) {
  const d = new Date();
  const seconds = d.getSeconds()
  const angleStep = (Math.PI * 2) / 60
  player.api.rotate(instanceID, [seconds * angleStep, 0, 0, 1], {duration: 0.1})
}

const rotate_m = function (instanceID, doNow) {
  // rotate in increments of a quarter minute
  const d = new Date();
  const seconds = d.getSeconds()
  const minutes = d.getMinutes()
  const angleStep = (Math.PI * 2) / 240
  if (seconds % 15 === 0 || doNow) {
    player.api.rotate(instanceID, [(minutes * 4) * angleStep + (seconds / 15) * angleStep, 0, 0, 1], {duration: 0.5})
  }
}

const rotate_h = function (instanceID, doNow) {
  // rotate in increments of a fifth hour
  const d = new Date();
  const minutes = d.getMinutes()
  const hours = d.getHours()
  const angleStep = (Math.PI * 2) / 60
  if (minutes % 12 === 0 || doNow) {
    player.api.rotate(instanceID, [(hours * 5) * angleStep + (minutes / 12) * angleStep, 0, 0, 1], {duration: 2})
  }
}

export default { 
    startTime
}
