import _base from './_base-module'
import templateData from 'ProjectData/template'

const state = {
  lights: {
    0: null,
    1: null,
    2: null,
  },
  lights2d: {
    0: [-100, -100],
    1: [-100, -100],
    2: [-100, -100],
  },
  lights3d: {
    0: [0, 0, 0],
    1: [0, 0, 0],
    2: [0, 0, 0],
  },
  lightslatlon: {
    0: [0, 0],
    1: [0, 0],
    2: [0, 0],
  },
  lightmoves: false,
  all: [],
}

const getters = {
  ..._base.getters,
  getLight: (state) => (id) => {
    return state.lights[id]
  },
  getLight2d: (state) => (id) => {
    return state.lights2d[id]
  },
  getLight3d: (state) => (id) => {
    return state.lights3d[id]
  },
  getLightLatlon: (state) => (id) => {
    return state.lightslatlon[id]
  },
}

const actions = {
  fetchData ({ commit }) {
    commit('clearAll')
    templateData.nmalight && templateData.nmalight.forEach(item => {
        commit('addItem', item)
    })
  },
  setLight ({commit}, {id, light}) {
    commit('setLight', {id: id, light: light})
  },
  setLight2d ({commit}, {id, coord}) {
    commit('setLight2d', {id: id, coord: coord})
  },
  setLight3d ({commit}, {id, coord}) {
    commit('setLight3d', {id: id, coord: coord})
  },
  setLightmoves ({commit}, {value}) {
    commit('setLightmoves', {value: value})
  },
  setLightLatLon ({commit}, {id, coord}) {
    commit('setLightLatLon', {id: id, coord: coord})
  }
}

const mutations = {
  ..._base.mutations,
  clearAll (state) {
    state.all = []
  },
  addItem (state, item) {
    state.all.push(item)
  },
  setLight (state, payload) {
    state.lights[payload.id] = payload.light
  },
  setLight2d (state, payload) {
    state.lights2d[payload.id] = payload.coord
  },
  setLight3d (state, payload) {
    state.lights3d[payload.id] = payload.coord
  },
  setLightmoves (state, payload) {
    state.lightmoves = payload.value
  },
  setLightLatLon (state, payload) {
    state.lightslatlon[payload.id] = payload.coord
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
