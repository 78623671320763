import player from './player'


const getAnimations = function () {
    let api = player.getApi()

    return new Promise ((resolve, reject) => {
        if (api) {
            api.getAnimations(function (err, animations) {
                if (err) reject(err)
                resolve(animations)
            })
        } else reject()
    })
}
const play = function () {
    let api = player.getApi()

    return new Promise ((resolve, reject) => {
        if (api) {
            api.play(function (err) {
                if (err) reject(err)
                resolve()
            })
        } else reject()
    })
}

const pause = function () {
    let api = player.getApi()

    return new Promise ((resolve, reject) => {
        if (api) {
            api.pause(function (err) {
                if (err) reject(err)
                resolve()
            })
        } else reject()
    })
}

const seekTo = function (seconds) {
    let api = player.getApi()
    if (seconds === null) seconds = 0

    return new Promise ((resolve, reject) => {
        if (api) {
            api.seekTo(seconds, function (err) {
                if (err) reject(err)
                resolve()
            })
        } else reject()
    })
}

const setSpeed = function (speed) {
    let api = player.getApi()
    if (speed === null) speed = 0

    return new Promise ((resolve, reject) => {
        if (api) {
            api.setSpeed(speed, function (err, speed) {
                if (err) reject(err)
                resolve(speed)
            })
        } else reject()
    })
}

export default { play, pause, seekTo, setSpeed, getAnimations }
