import _base from './_base-module'
import templateData from 'ProjectData/template'
import libraryData from 'ProjectData/library'

const state = {
  type: 'environment',
  library: [],
  template: [],
  payload: {}
}

const getters = {
  ..._base.getters,
}

const actions = {
  fetchData ({ commit }, {scene}) {
    commit('setLibrary', libraryData.environment)
    commit('setTemplate', templateData.environment)
    commit('setLibraryTemplatePayload', scene.environment)
    commit('compileTemplateData')
  }
}

const mutations = {
  ..._base.mutations,

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
