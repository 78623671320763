const state = {
  all: {}
}

const getters = {
  getTexture: (state) => (uid) => {
    return state.all[uid]
  },
  getTextureByName: (state) => (name) => {
    for (let uid in state.all) {
      if (state.all[uid].name === name) {
        return state.all[uid]
      }
    }
  }
}

const actions = {
  storeTexture ({ commit }, texture) {
    commit('storeTexture', texture)
  },
}

const mutations = {
  storeTexture(state, texture) {
    // console.log('storing', texture.name, texture.uid)
    state.all[texture.uid] = texture
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
