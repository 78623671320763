<script>
import playerTools from '@/tools/sketchfab/player'
import store from 'Store'
import splash from '../splash'
import annotationoverlay from '../annotation-overlay'
import nmalightoverlay from '../project/nma-light-overlay'
// import FabCommercial from '../fab-commercial.vue'
import FabCamera from '../fab-camera.vue'
import configure from "@/tools/configurator/configure"

export default {
  name: 'playerSketchfab',
  props: {
    sceneuid: String
  },
  components: {
    annotationoverlay,
    splash,
    nmalightoverlay,
    // FabCommercial,
    FabCamera,
  },
  data: function () {
    return {
    }
  },  

  computed: {
    loaded () {
      return this.$store.getters['scenes/getsceneLoaded']
    },
    cameraMoves () {
      return this.$store.state.ui.cameraMoves
    },
    ui () {
      return this.$store.getters['ui/getState']
    },    
    myStyle () {
      const pointer = this.ui.navigatebyannotationonly ? 'none' : 'all'

       return {
        'pointer-events': pointer
      }     
    }
  },
  mounted () {
    configure.loadScene(this.sceneuid)
  },
  methods: {}  
}
</script>

<template>
  <v-container id="playercontainer" class="fill-height player-container" fluid>

    <fab-camera v-if="loaded"/>
    <!-- <fab-commercial v-if="loaded"/> -->
    <splash v-if="!loaded"/>
    <annotationoverlay v-if="loaded" :cameraMoves="cameraMoves"/>
    <nmalightoverlay v-if="loaded" :cameramoves="cameraMoves"/>
    <iframe class="player" :class="{'iframe__hidden': !loaded}" id="player__iframe" :style="myStyle">
    </iframe>
  </v-container>
</template>

<style lang="scss" scoped>

.player-container {
  padding:0px;
  align-items: start;
  position: relative;
}
.iframe {
  height:100%;
  width: 100%;
  position: relative;

}
.player {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  border: 0;
  // opacity: 1;
  transition: opacity 2.0s ease;
  // background: #ddd;
  position: relative;
  z-index:2;

  &.iframe__hidden {
    opacity: 0;
  }

}

</style>
