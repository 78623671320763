<script>
import materialswappablemenu from './materialswappable-menu'
import materialswappeemenu from './materialswappee-menu'

export default {
  name: 'materialswappableoption',
  components: {
    materialswappablemenu,
    materialswappeemenu
  },
  props: {
    optionname: String
  },
  computed: {
    materialswappable () {
      return this.$store.getters['materialswappable/getItemByName'](this.optionname)
    },
    isSwappee () {
      return this.materialswappable.type === 'swappee'
    },
    isSwappable () {
      return this.materialswappable.type === 'swappable'
    },
    icon () {
      if (this.materialswappable.icon) {
        return (process.env.BASE_URL + this.materialswappable.icon)
      } else {
        return null
      }
    },
    swatch () {
      if (this.isSwappee) {
        if (this.materialswappable && this.materialswappable.swatch) {
          return this.materialswappable.swatch
        } else {
          return null
        }
      }
      if (this.isSwappable) {
        const swp = this.$store.getters['materialswappable/getItemByName'](this.materialswappable.visible)
        if (swp && swp.swatch) {
          return swp.swatch
        } else {
          return null
        }
      }
      return null
    }
  },
  methods: {
  }  
}
</script>

<template>
  <v-list-group
    v-if="materialswappable && materialswappable.options.length > 0"
    color='accent'
  >
    <template v-slot:activator>
      <v-list-item-avatar 
        v-if="swatch" 
        :color="swatch" 
        class="elevation-4"
        left
      />
      <v-list-item-avatar v-if="icon" class="elevation-4">
        <v-img :src="icon"/>
      </v-list-item-avatar>      

      <v-list-item-content two-line>
          <v-list-item-title v-text="materialswappable.title"></v-list-item-title>
          <v-list-item-subtitle>{{ materialswappable.currentTitle }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
      <v-divider></v-divider>

      <materialswappablemenu v-if="isSwappable" :options="materialswappable.options" :name="materialswappable.name"/>
      <materialswappeemenu v-if="isSwappee" :options="materialswappable.options" :name="materialswappable.name"/>

  </v-list-group>
</template>