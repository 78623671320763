class MenuItem {
  constructor(menuitem) {
    this.name = menuitem.name
    this.expand = menuitem.expand
    this.title = menuitem.title
    this.icon = menuitem.icon
    this.options = menuitem.options ? menuitem.options : null
    this.stepped = menuitem.stepped ? menuitem.stepped : false
    if (menuitem.submenu) {
      this.submenu = []
      for (let sub of menuitem.submenu) {
        this.submenu.push(new MenuItem(sub))
      }
    } else {
      this.submenu = null  
    }
  }
}

export default MenuItem