<script>
import configuratordrawer from 'Components/framework/configurator-drawer'
import playerSketchfab from 'Components/sketchfab/player'
import playerBabylon from 'Components/babylon/player'
import cardcommercial from 'Components/card-commercial'
// import cta from 'Components/cta'
import projectData from 'ProjectData/project'
import store from '../../store'
import MobileNav from "./mobile-nav.vue";
import MobileTopBar from "./mobile-top-bar.vue";
import DesktopTopBar from "./desktop-top-bar.vue";
import MobileModelPicker from "./mobile-model-picker.vue";
import Summary from "./model-summary.vue";
import configure from "@/tools/configurator/configure"
import DesktopModelPicker from './desktop-model-picker.vue'
import DialogCommercial from 'Components/dialog-commercial'

export default {
  components: {
    configuratordrawer,
    playerSketchfab,
    playerBabylon,
    cardcommercial,
    // cta,
    MobileNav,
    MobileTopBar,
    MobileModelPicker,
    Summary,
    DesktopModelPicker,
    DialogCommercial,
    DesktopTopBar,
  },    
  props: {
    source: String,
  },
  data: () => ({
    dataParsedSketchfab: false,
    dataParsedBabylon: false,
    landscape: false,
    menuPosition: "middle",
    swipeDirection: null,
  }),
  created () {
    console.log('CONFIGURATOR CREATED')

    this.$store.dispatch('ui/fetchData', {ui: projectData.ui})
    this.$vuetify.theme.dark = (this.ui.theme === 'dark')

    // get all scenes
    this.$store.commit('scenes/init', projectData.scenes)
    const scenes = this.$store.getters['scenes/getAll']

    //get scene uid from query. Load that scene if it's found. Otherwise just load the first one
    let query = Object.assign({}, this.$route.query)
    let scene = null
    if (query.scene) {
      scene = scenes.find(scene => {
        if (scene.sceneuid === query.scene) {
          return this.$store.getters['scenes/getScene'](query.scene)
        }
      })
      // delete query.scene
      // this.$router.replace({ query })
    }
    if (!scene) {
      scene = this.$store.getters['scenes/getScene'](scenes[0].sceneuid)
    }

    if (scene) {
      this.$store.dispatch('scenes/setCurrentScene', {scene: scene})
    }

    if (this.currentScene.sceneuid === undefined) {
      //open the models panel
      this.$router.push({name:'models'})
    } else {
      configure.parseProjectData(this.currentScene)

      if (this.currentScene.engine == null || this.currentScene.engine == 'sketchfab') {
        this.dataParsedSketchfab = true
      }      
      if (this.currentScene.engine == 'babylon') {
        this.dataParsedBabylon = true
      }
    }
  },
  computed: {
    bottomnav: {
      get: function () { return this.$store.state.ui.bottomnav },
      set: function (newValue) { this.$store.commit('ui/setBottomnav', newValue) }
    },      
    desktopnav: {
      get: function () { return this.$store.state.ui.desktopnav },
      set: function (newValue) { this.$store.commit('ui/setDesktopnav', newValue) }
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    sceneLoaded () {
      return this.$store.state.scenes.sceneLoaded
    },
    currentScene () {
      return this.$store.getters['scenes/getCurrentScene']
    },
    ui () {
      return this.$store.getters['ui/getState']
    },
    headerlogo () {
      if (this.ui.headerlogo) {
        return (process.env.BASE_URL + this.ui.headerlogo)
      } else {
        return null
      }
    },
    backgroundColor () {
      let bg = 'white'
      if (this.ui.background && this.ui.background.type != 'sketchfab') {
        bg = this.ui.background.color
      }
      return bg
    },
    background () {
      let bg = {image: null, type: 'sketchfab'}

      if (this.ui.background && this.ui.background.type === 'image') {
        bg.type = this.ui.background.type
        // if (this.ui.background.type === color)
        const imagePath = require.context('../../assets/', true)
        bg.image = imagePath(this.ui.background.image, true)
      }
      return bg
    },
  },
  mounted () {
    if (!this.mobile) this.desktopnav = true;

    const handleUserInteraction = () => {
      this.setViewHeight();

      if (window.innerWidth > window.innerHeight) {
        this.landscape = true;
      } else {
        this.landscape = false;
        // this.drawer = false;
      }
    };

    handleUserInteraction();

    const debouncedSetHeight = this.$nextTick(() => this.setViewHeight);

    window.addEventListener("orientationchange", handleUserInteraction, false);
    window.addEventListener("resize", handleUserInteraction);

    this.$once("destroyed", () => {
      window.removeEventListener("resize", debouncedSetHeight);
    });
      
      


    store.commit('ui/updateItemByName', {key: 'bottomnav', value: false})

    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      window.addEventListener('resize', this.getWindowHeight)

      this.getWindowWidth()
      this.getWindowHeight()
    })    
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
    window.removeEventListener('resize', this.getWindowHeight)
  },  
  methods: {
    setViewHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    getWindowWidth (event) {
      this.windowWidth = document.documentElement.clientWidth
    },
    getWindowHeight (event) {
      this.windowHeight = document.documentElement.clientHeight
    },
    openModelpicker () {
      this.drawer = true
    },
    toggleDesktopNav () {
      this.desktopnav = !this.desktopnav
    }
  }
}
</script>

<template>
  <div>
  <v-app 
    id="configurator"
    class="force-full-height"
  >
    <DesktopModelPicker />
    <DialogCommercial />
    <Summary/>

    <v-navigation-drawer
      app 
      fixed
      :width="$vuetify.breakpoint.smAndDown ? '50vw' : $vuetify.breakpoint.mdAndDown ? '35vw': '400px'"
      clipped
      color="card"
      v-model="desktopnav"
    >
      <template v-slot:prepend>

        <v-toolbar 
          color="cardaccent2"
          class="pl-4 pr-2"
          height="72"
          elevation=4
        >
          <div
            color="card"
            class="d-flex flex-column ml-0"
            style="max-width: 80%;"
          >
            <div class="text-truncate text-h5 font-weight-medium">{{ currentScene.scenetitle }}</div>
            <div class="mt-n1 text-truncate text-sm-subtitle-1 font-weight-light text--secondary">{{ ui.projecttitle }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="card"
            class="ml-2 mt-0"
            fab
            depressed
            @click="toggleDesktopNav"
            v-if="desktopnav"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

        </v-toolbar>
      </template>

      <configuratordrawer v-if="dataParsedSketchfab && sceneLoaded"/>
      <configuratordrawer v-if="dataParsedBabylon"/>

      <template 
        v-if="ui.contactcard"
        v-slot:append
      >
        <!-- <cta v-if="ui.checkout"/> -->
        <cardcommercial
          :contactcard="ui.contactcard"
          :summary="ui.checkout"
          :pricing="ui.pricing"
        />
      </template>
    </v-navigation-drawer>

      <v-main class="overflow-hidden">
        <MobileModelPicker/>
        <v-sheet class="d-flex flex-column" height="100%">
          <MobileTopBar
            v-if="mobile"
            :headerlogo="headerlogo"
          />
          <DesktopTopBar
            v-if="!mobile"
            :headerlogo="headerlogo"
          />

          <div class="flex-column flex-fill" 
          >
            <playerSketchfab v-if="dataParsedSketchfab" :sceneuid="currentScene.sceneuid"/>
            <playerBabylon v-if="dataParsedBabylon"/>
            <v-card 
              class="background d-flex align-center" 
              :color="backgroundColor" 
              flat
            >
              <v-img 
                class="backgroundimage" 
                v-if="background.image" 
                :src="background.image" 
                contain 
              />
            </v-card>
          </div>

          <MobileNav
            v-if="mobile"
            :landscape="landscape"
            :scenetitle="currentScene.scenetitle"
            :projecttitle="ui.projecttitle"
            :contactcard="ui.contactcard"
            :summary="ui.checkout"
            :pricing="ui.pricing"
          >
          </MobileNav>
        </v-sheet>
      </v-main>

  </v-app>
  </div>
</template>

<style lang="scss" scoped>
  .tabs {
    z-index: 1;
  }
  .titles {
    margin-left: 12px;
  }
  .backgroundimage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    height: 70%;
    opacity: 0.4;
    z-index: 1;
  }
  .background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 0px;
  }

  .headerlogo {
    max-width: 250px;  
  }

  .navdrawer {
    @media screen and (max-width: 600px) {
      max-height: 65%;
    }      
  }

  .divider-line {
    width: 100px;
    height: 7px !important;
    background: #aaaaaa;
  }

  .force-full-height ::v-deep .v-application--wrap {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .modelpickerbutton {
    z-index: 3;
  }

  .v-toolbar {
    z-index: 2;
  }
</style>

