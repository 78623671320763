<script>
import liquidPreset from './liquid-preset'
import liquidManual from './liquid-manual'
import configure from '@/tools/configurator/configure'
import store from '../../store'

export default {
  name: 'liquidOption',
  components: {
    liquidPreset,
    liquidManual
  },
  props: {
    optionname: String
  },
  data: () => ({
    expanded: null,
    disabled: true
  }),  
  methods: {
    toggleSwitch () {
      // console.log(this.theLiquid)
      // this.$store.commit('visibilitySingleList/setVisibility', {optionName: this.option.name, switch: this.theSwitch})
      this.disabled = !this.theSwitch
      configure.SetVisibilitySingle(this.theSwitch, this.theLiquid.visibility.objects, false)
      configure.SetMaterialOption(this.theLiquid.material, this.theLiquid.material, this.theLiquid.liquidsets)

    },

  },
  mounted: function () {
    this.theSwitch = this.theLiquid.visibility.visible
    configure.setView(this.theView.payload)
    this.toggleSwitch()
  },    
  // updated: function () {
  //   store.dispatch('liquid/setVisibility', {liquid: this.theLiquid, visible: true})

  //   // this.theSwitch = this.theLiquid.visibility.visible
  //   // configure.setView(this.theView.payload)
  //   // this.toggleSwitch()
  // },  
  computed: {
    theLiquid () {
      return store.getters['liquid/getLiquidByProperty']('name', this.optionname)
    },
    theView () {
      return store.getters['views/getItemByName'](this.theLiquid.view)
    },
    liquidPresets () {
      return this.theLiquid.liquidpresets.filter(item => item.options.length > 0)
    },
    liquidManuals () {
      return this.theLiquid.liquidmanuals
    },
    theSwitch: {
      get: function () {
        return this.theLiquid.visibility.visible
        // const item = this.$store.getters['visibilitysingle/getItemByName'](this.optionname)
        // return item.visible
        // return true
      },
      set: function (newValue) {
        store.dispatch('liquid/setVisibility', {liquid: this.theLiquid, visible: newValue})
        this.toggleSwitch()
      }
    }      
    // icon () {
    //   if (this.theLiquid.icon) {
    //     return (process.env.BASE_URL + this.theLiquid.icon)
    //   } else {
    //     return null
    //   }
    // },       
  }
}
</script>

<template>
<v-card>
  <v-list-item-group>
  <v-list-item 
    @click="toggleSwitch"
  >
    <template 
      v-slot:default="{ active, toggle }"
    >
      <v-list-item-content >
        <v-list-item-title

        >Show branding</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action >
        <v-switch 
          color="accent" 
          @click="toggle" 
          :input-value="active" 
          v-model="theSwitch"
        >
        </v-switch>
      </v-list-item-action>
    </template>
  </v-list-item>
  </v-list-item-group>

  <div v-for="preset in liquidPresets" :key="preset.id">
    <liquidPreset :liquidname="preset.liquidname" :liquid="theLiquid" :disabled="disabled"/>
  </div>

  <div v-for="manual in liquidManuals" :key="manual.id">
    <liquidManual :liquidname="manual.liquidname" :liquid="theLiquid" :disabled="disabled"/>
  </div>

  <!-- <div v-for="manual in liquidManuals" :key="manual.id">
    <liquidManual :manual="manual" :liquid="liquid" :disabled="disabled"/>
  </div>   -->

</v-card>
</template>

<style lang="scss" scoped>
</style>
