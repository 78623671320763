import _base from './_base-module'
import templateData from 'ProjectData/template'

const state = {
  all: []
}

const getters = {
  ..._base.getters
}

const actions = {
  fetchData ({ commit }) {
    commit('clearAll')
    if (templateData.presets) {
      for (let preset of templateData.presets) {
        commit('addPreset', preset)
      }
    }
  }
}

const mutations = {
  ..._base.mutations,
  addPreset (state, preset) {
    state.all.push(preset)
  },
  clearAll (state) {
    state.all = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
