<script>
import configure from '@/tools/configurator/configure'

export default {
  components: {
  },
  data: () => ({
    viewIdx: 1
  }),
  computed: {
    views () {
      return this.$store.state.views.all
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },    
  },
  methods: { 
    nextView () {
      configure.setView(this.views[this.viewIdx].payload)
      this.viewIdx += 1
      if (this.viewIdx > this.views.length-1) this.viewIdx = 0
    }
  },
  mounted () {
  }
}
</script>

<template>
  <v-btn
    fab
    absolute
    :small="mobile"
    color="accent"
    class="camera-fab mr-2 mb-2"
    @click="nextView"
  >
    <v-icon 
      :large="!mobile"
    >mdi-video</v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.camera-fab {
  right: 0px;
  bottom: 0px;
}
</style>
