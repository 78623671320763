<script>

export default {
  name: 'desktoptopbar',
  components: {
  },    
  props: {
    headerlogo: String,
  },
  data: () => ({
  }),
  computed: {
    desktopnav () {
      return this.$store.state.ui.desktopnav
    },
    scenes () {
      return this.$store.getters['scenes/getAll']
    },
  },
  mounted () {
  },
  methods: {
    setDesktopModelpicker () {
      this.$store.commit('ui/setDesktopModelpicker', true)
    },
    showDesktopNav () {
      this.$store.commit('ui/setDesktopnav', true)
    }
  }
}
</script>

<template>
  <div
    class="desktoptopbar d-flex"
  >
    <v-btn
      class="ml-2 mt-2"
      fab
      elevation=2
      @click="showDesktopNav"
      v-if="!desktopnav"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>

    <v-btn
      class="ml-2 mt-2"
      fab
      elevation=2
      @click="setDesktopModelpicker"
      v-if="scenes.length > 1"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    
    <v-img 
      class="headerlogo mr-2 mt-2" 
      v-if="headerlogo" 
      height="60" 
      position="right"
      contain
      :src="headerlogo"
    >
    </v-img>
  </div>
</template>

<style lang="scss" scoped>
.desktoptopbar {
  position: absolute;
  z-index: 3;
  width: 100%;
}
.headerlogo {
  right: 0px;
  top: 0px;
}
</style>

