<script>
import cardportfolio from 'Components/card-portfolio'

export default {
  name: 'dialogCommercial',
  components: {
    cardportfolio
  },
  data: () => ({
    projects: [
      // {
      //   title: 'Zodiac Nautic',
      //   subtitle: 'Semi inflatable boats',
      //   text: 'A bespoke configurator. Lots of color options and different products to choose from. Review and checkout your design and get a pdf with your design emailed to you automatically.',
      //   articleurl: 'https://www.klaasnienhuis.nl/2016/07/zodiac-nautic-boat-configurator/',
      //   url: 'https://configure.zodiac-nautic.com/',
      //   img: './commercial/zodiac.jpg'
      // },
      {
        title: 'Emory University',
        subtitle: 'Digital Anatomy',
        text: 'Anatomy education. A flexible web-app to show different parts of the human anatomy.',
        articleurl: 'https://www.klaasnienhuis.nl/2016/07/zodiac-nautic-boat-configurator/',
        url: 'https://www.klaasnienhuis.nl/portfolio/emory-university-3d-anatomy/',
        img: './commercial/emory_lungs.jpg'
      },
      {
        title: 'F.Domes',
        subtitle: 'Geodetic dome tents',
        text: 'A custom configurator with a responsive design. Speed up the sales process with live princing and shipping info.',
        articleurl: 'https://www.klaasnienhuis.nl/2018/06/f-domes-geodesic-dome-configurator/',
        url: 'https://3d.fdomes.com/',
        img: './commercial/fdomes.jpg'
      },
      {
        title: 'Gasgrill',
        subtitle: 'Professional gas grills',
        text: 'A responsive configurator with robust logic and rules. Create your new grill on your laptop or mobile and step into a live co-creation session with a sales agent.',
        articleurl: 'https://www.klaasnienhuis.nl',
        url: 'https://www.gasgrill-shop.com/3d-konfigurator/index.html#/',
        img: './commercial/gasgrill.jpg'
      }
    ]
  }),
  computed: {
    dialog: {
        get () {
            return this.$store.state.ui.projectsdialog
        },
        set () {
            this.$store.commit('ui/updateItemByName', {key: 'projectsdialog', value: false})
        }
    },
      dialogColumns () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '12'
        case 'sm': return '12'
        case 'md': return '4'
        case 'lg': return '4'
        case 'xl': return '4'
        default: return '4'
      }
    },

    dialogWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm': return 600
        case 'md': return 800
        case 'lg': return 1000
        case 'xl': return 1200
        default: return 800
      }
    }, 
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    :width=dialogWidth
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-container fluid>
        <v-row dense>
          <v-col cols=12>
            <v-card
              color="card"
              outlined
            >
              <v-list-item >
                <v-list-item-avatar>
                  <v-img src="../assets/img/Klaas_profilePicture.jpg" ></v-img>      
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="headline">Klaas Nienhuis</v-list-item-title>
                  <v-list-item-subtitle color="black">3D development</v-list-item-subtitle>
                </v-list-item-content> 
              </v-list-item>

              <v-card-text class="px-7 pt-0 textcontrast--text text-body-1">
                Hi, I'm Klaas and I've built this demo. Get in touch to work with a team of specialists. We're experienced in building 3d configurators from A to Z.
                Visit <a href="https://www.klaasnienhuis.nl">klaasnienhuis.nl</a> for more information and read what I can do to help your business.
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  href="https://klaasnienhuis.freshsales.io/web_forms/404bcc64bc2b7d2fadc81f06d07264e35c21a9cc6c36e680a1bd52d8b929b501/form.html" 
                  target="_blank"
                >
                  Get in touch
                  <v-icon right>mdi-email</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="dialog = !dialog"
                >
                  Close dialog
                </v-btn>    
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col
            v-for="project in projects"
            :key="project.title"
            :cols=dialogColumns
          >
            <cardportfolio :project="project">
            </cardportfolio>
          </v-col>
        </v-row>
      </v-container>          
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
</style>
