<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'environmentmenu',
  props: {
    options: Array,
    name: String
  },
  computed: {
    currentEnvironment () {
      return this.$store.getters['studiogroup/getItemByName'](this.name)
    }
  },
  methods: {
    setEnvironment (environment, option) {
      // using the store from the import helps keep the ui updated even when it's
      // not visible. e.g. when it's triggered from the states menu
      this.$store.dispatch('studiogroup/setCurrentEnvironment', {environment: environment, option: option})
      configure.SetStudioGroup(option.payload)
    },    
  }  
}
</script>

<template>
<div>
  <v-sheet
    class="d-flex flex-wrap pa-2"
    elevation="0"
    color="white"
  >
    <v-sheet
      v-for="(option, index) in options" 
      :key="index" 
    >
      <v-avatar
        tile
        v-if="option.swatch" 
        :color="option.swatch" 
        @click="setEnvironment(currentEnvironment, option)"
        size=62
      >
      </v-avatar>

      <v-avatar
        tile
        v-if="option.icon" 
        @click="setEnvironment(currentEnvironment, option)"
        size=62
      >
        <v-img :src="option.icon"/>
      </v-avatar>
    </v-sheet>
  </v-sheet>
</div>
</template>