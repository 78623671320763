<script>
import configure from '@/tools/configurator/configure'
import transformrotateslider from './transform-rotate-slider'

export default {
  name: 'transformrotatemenu',
  components: {
    transformrotateslider
  },
  props: {
    options: Array,
    name: String
  },
  data: () => ({
      rotation: 0
  }),  
  computed: {
    currentTransform () {
      return this.$store.getters['transform/getItemByName'](this.name)
    }
  },
  methods: {
  }  
}
</script>

<template>
<div>
  <v-sheet
    class="pa-2"
    elevation="0"
    color="cardaccent"
  >
    <v-sheet
      v-for="(option, index) in options" 
      :key="index" 
      color="cardaccent"
    >
    <transformrotateslider :option="option" :name="option.name"/>

    </v-sheet>
  </v-sheet>

  
</div>
</template>