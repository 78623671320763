<script>
import environmentmenu from './environment-menu'
import environmentslider from './environment-slider'

export default {
  name: 'environmentoption',
  components: {
    environmentmenu,
    environmentslider
  },
  data: () => ({
    show: false,
    exposure: {
      title: "Exposure",
      name: "exposure",
      min: 0,
      max: 5,
      step: 0.1
    },
    rotation: {
      title: "Rotation",
      name: "rotation",
      min: 0,
      max: Math.PI * 2,
      step: 0.1
    },
    shadow: {
      title: "Shadow",
      name: "lightIntensity",
      min: 0,
      max: 10,
      step: 0.1
    },
  }),  
  props: {
    optionname: String
  },
  computed: {
    environment () {
      return this.$store.getters['studiogroup/getItemByName'](this.optionname)
    },
    currentOption () {
      let current = this.environment.options.find(item => item.name === this.environment.visible)
      return current
    },
  },
  methods: {
  }  
}
</script>

<template>
  <v-card>
    <div class="d-flex flex-no-wrap">
      <v-list-item-avatar 
        v-if="environment.swatch" 
        :color="environment.swatch" 
        class="elevation-4 ma-2"
        left
        tile
        size="80"
      />
      <v-list-item-avatar 
        v-if="environment.icon" 
        class="elevation-4 ma-2"
        left
        tile
        size="80"
      >
        <v-img 
          :src="environment.icon"
        />
      </v-list-item-avatar> 
      <div>
        <v-card-title>
          {{ environment.title }}
        </v-card-title>

        <v-card-subtitle
        >
          {{ environment.currentTitle }}
        </v-card-subtitle>
      </div>
    </div>

    <environmentslider :optiontitle="exposure.title" :optionname="exposure.name" :min="exposure.min" :max="exposure.max" :step="exposure.step" :environment="environment" :currentOption="currentOption" />
    <environmentslider :optiontitle="rotation.title" :optionname="rotation.name" :min="rotation.min" :max="rotation.max" :step="rotation.step" :environment="environment" :currentOption="currentOption" />
    <environmentslider :optiontitle="shadow.title" :optionname="shadow.name" :min="shadow.min" :max="shadow.max" :step="shadow.step" :environment="environment" :currentOption="currentOption" />


    <v-card-actions>
      <v-btn
        text
        @click="show = !show"
      >
        Environment list
        <v-icon 
          right
        >
          {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <environmentmenu :options="environment.options" :name="environment.name"/>
      </div>
    </v-expand-transition>

  </v-card>
</template>