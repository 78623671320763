import scene from './scene'
import {PBRMaterial,BackgroundMaterial, Texture} from '@babylonjs/core';
import {Color3} from '@babylonjs/core';
import {SceneLoader} from '@babylonjs/core';
import {MeshBuilder} from '@babylonjs/core';
import materialTools from 'bjs_tool/materials'

// import {SceneLoader} from '@babylonjs/core/loaders/sceneloader';
// import * as BABYLON from '@babylonjs/core';
import store from 'Store'

let engraving, ground;

const buildGround = function (materialName) {
    let sc = scene.getScene()

    // //Appliance
    // const textOption = store.getters['text/getTextitem'](materialName)
    // let engraving = materialTools.createTextTexture(textOption)
    // let groundMtl = new PBRMaterial(materialName, sc);
    // groundMtl.roughness = 1.0;
    // groundMtl.albedoTexture = engraving;
    // ground = MeshBuilder.CreateGround('ground', {width: 3000, height: 3000}, sc)
    // ground.receiveShadows = true
    // ground.material = groundMtl;

    //Footjoy
    ground = MeshBuilder.CreateGround('ground', {width: 1, height: 1}, sc)
    ground.receiveShadows = true

    var backgroundMaterial = new BackgroundMaterial('backgroundMaterial', sc);
    backgroundMaterial.diffuseTexture = new Texture('/data/footjoy/textures//backgroundGround.png', sc);
    backgroundMaterial.diffuseTexture.hasAlpha = true;
    backgroundMaterial.opacityFresnel = false;
    backgroundMaterial.shadowLevel = 0.5;
    ground.material = backgroundMaterial;    
}

const loadModel = function (filePath) {
    return new Promise((resolve, reject) => {
        let sc = scene.getScene()
        SceneLoader.Append('/data/', filePath, sc, function (newScene) {
            resolve(newScene)
        });
    })
}

export default { loadModel, buildGround }
