<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'visibilitysingleoption',
  components: {
  },
  props: {
    optionname: String
  },
  computed: {
    theOption () {
      return this.$store.getters['visibilitysingle/getItemByName'](this.optionname)
    },
    theSwitch: {
      get: function () {
        const item = this.$store.getters['visibilitysingle/getItemByName'](this.optionname)
        return item.visible
      },
      set: function (newValue) {
        this.$store.commit('visibilitysingle/setVisibility', {optionName: this.optionname, switch: newValue})
        this.toggleSwitch()
      }
    }
  },
  methods: {
    toggleSwitch () {
      this.$store.commit('visibilitysingle/setVisibility', {optionName: this.optionname, switch: !this.theOption.visible})
      configure.SetVisibilitySingle(this.theOption.visible, this.theOption.objects, this.theOption.exactname)
    }
  }  
}
</script>

<template>
<v-list-item-group>
  <v-list-item 
    @click="toggleSwitch"
  >
    <template 
      v-slot:default="{ active, toggle }"
    >
      <v-list-item-content >
        <v-list-item-title
          v-if="theOption"
          v-text="theOption.title"
        ></v-list-item-title>
      </v-list-item-content>
      <v-list-item-action >
        <v-switch 
          color="accent" 
          @click="toggle" 
          :input-value="active" 
          v-model="theSwitch"
        >
        </v-switch>
      </v-list-item-action>
    </template>
  </v-list-item>
</v-list-item-group>
</template>