import player from './player'

const setLight = function (lightId, options) {
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.setLight(lightId, options, function (err) {
        if (err) {
          reject(err)
        } else {
          resolve()
        }
      })
    }
  })
}

const getLight = function (lightId) {
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.getLight(lightId, function (err, light) {
        if (err) {
          reject(err)
        } else {
          resolve(light)
        }
      })
    }
  })
}

const setLightFeatureEnabled = function (enable) {
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.setLightFeatureEnabled(enable, function (err) {
        if (err) {
          reject(err)
        }
        resolve()
      })
    }
  })
}

window.configuratorapi.setLight = setLight
window.configuratorapi.getLight = getLight

export default { setLight, getLight, setLightFeatureEnabled }
